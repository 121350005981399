import http from "@/axios/index"
import { URI } from "@/config/axios"

// GET /api/ruleChains{?textSearch,idOffset,textOffset,limit}
export const getList = (params = {}) => {
  return http({
    url: URI.system + 'api/ruleChains',
    method: 'get',
    params
  })
}

//POST /api/ruleChain
export const addRule = (data = {}) => {
  return http({
    url: URI.system + 'api/ruleChain',
    method: 'post',
    data
  })
}


//DELETE /api/ruleChain/{ruleChainId}
export const deleteRule = (id='') => {
  return http({
    url: URI.system + 'api/ruleChain/' + id,
    method: 'delete',
  })
}

//GET /api/components{?componentTypes}
export const getNodes = (type = 'FILTER,ENRICHMENT,TRANSFORMATION,ACTION,EXTERNAL') => {
  return http({
    url: URI.system + 'api/components',
    method: 'get',
    params:{
      componentTypes:type
    }
  })
}

// events /api/events/RULE_NODE/ad1603b0-8214-11eb-829f-930716ac2347/STATS?tenantId=ad0317f0-8214-11eb-829f-930716ac2347&limit=20&startTime=1623658043048&endTime=1623744443048
export const getEventsData = (id,name,params = {}) => {
  return http({
    url:URI.system + `api/events/RULE_NODE/${id}/${name}`,
    method:'get',
    params:{
      limit:9999,
      ...params
    }
  })
}

// api/ruleChain/a85003f0-cf33-11eb-8d53-7983da4b64e8/metadata
export const getMetadata = (id) => {
  return http({
    url:URI.system + `api/ruleChain/${id}/metadata`,
    method:'get'
  })
}

// 
export const saveMetadata = (data) => {
  return http({
    url:URI.system + `api/ruleChain/metadata`,
    method:'post',
    data
  })
}

// /api/ruleChain/3225fd20-baa6-11eb-8341-57e0771a3292/root
export const resetRootRuleChain = (id) => {
  return http({
    url:URI.system + `api/ruleChain/${id}/root`,
    method:'post'
  })
}

///api/ruleChain/testScript
export const testScript = (data) => {
  return http({
    url:URI.system + `api/ruleChain/testScript`,
    method:'post',
    data
  })
}

// 获取字典项
const TYPE_NAME_VALUE = {
  DEVICE:'devices',
  ASSET:'assets',
  ENTITY_VIEW:'entityViews',
  CUSTOMER:'customers',
  DASHBOARD:'dashboards',
}
let TYPE_NAME = '';
export const getTenantDict = (name,params = {}) => {
  TYPE_NAME = TYPE_NAME_VALUE[name] ? TYPE_NAME_VALUE[name] : name;
  return http({
    url:URI.system + `api/tenant/${TYPE_NAME}`,
    method:'get',
    params:{limit:9999,textSearch:'',...params}
  })
}
export const searchTenantDict = (textSearch,params = {}) => {
  if(!TYPE_NAME){return {}}
  return http({
    url:URI.system + `api/tenant/${TYPE_NAME}`,
    method:'get',
    params:{limit:50,...params,textSearch}
  })
}