<!-- 函数测试 -->
<template>
  <div class="fun-test">
    <!-- header -->
    <!-- <div class="ft--header">
        <span>测试脚本基础功能</span>
        <el-button icon="el-icon-close"></el-button>
    </div> -->
    <div class="ft--one ft--flex">
      <!-- msg json -->
      <div class="ft--msg ft--flex-item">
        <p class="ff--title">
          <!-- <span> [JSON] </span> -->
          <span>{{lang.funMsg}}</span>
        </p>
        <els-monaco-editor v-model="msgValue" :langsDisabled="true" class="ff--main" lang="json" :showHeader="false" theme="vs"></els-monaco-editor>
      </div>
      <!-- metadata item table -->
      <div class="ft--metadata ft--flex-item">
        <p class="ff--title">
          <!-- <span> [JSON] </span> -->
          <span> {{lang.funMet}} </span>
        </p>
        <div class="ff--main">
          <item-table
              :options="metadataOptions"
              :column="metaDataColumn"
              :showPagination="false"
              :showMenu="true"
              v-model="metadataValue"
            ></item-table>
        </div>

      </div>
    </div>
    <div class="ft--two ft--flex">
      <!-- 方法 -->
      <div class="ft--js ft--flex-item">
        <p class="ff--title">
          <span>{{ scriptTip }}</span>
        </p>
        <els-monaco-editor v-model="scriptValue" :langsDisabled="true" class="ff--main" :showHeader="false" theme="vs"></els-monaco-editor>
      </div>
      <!-- 返回值 -->
      <div class="ft--res ft--flex-item">
        <p class="ff--title">
          <!-- <span> [OBJECT] </span> -->
          <span> {{lang.funRes}} </span>
        </p>
        <els-monaco-editor :value="resultValue" :langsDisabled="true" class="ff--main" lang="json" :showHeader="false" theme="vs"></els-monaco-editor>
      </div>
    </div>
    <!-- footer -->
    <div class="ft--footer">
      <div>
        <el-button icon='el-icon-s-promotion' size="small" type="primary" :loading="runLoading" @click="handleRunTest">{{lang.run}}</el-button>
      </div>
      <div>
        <el-button icon="el-icon-check" size="small" type="success" @click="handleSave">{{lang.save}}</el-button>
        <el-button icon="el-icon-close" size="small" type="danger" @click="handleClose">{{lang.close}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {testScript} from "@/api/manager/rules-manager.js"

import itemTable from "../../../components/els-form/item-table/item-table.vue";
import mixinsI18n from "@/mixins/i18n.js"
export default {
  name: "funTest",
  mixins: [mixinsI18n],
  components: { itemTable },
  props: {
    argNames:{type:Array,default:() => ["msg", "metadata", "msgType"]},
    functionName:String,
    msg:{type:[String,Object],default:() => ({temperature:22.4,humidity:78})},
    msgType:{type:String,default:'POST_TELEMETRY_REQUEST'},
    metadata:{type:[Array,Object],default:() => ({deviceName: "Test Device",deviceType: "default",ts: "1627438621269"})},
    script:[String],
    scriptType:[String],
    context:[Object],
    formElem:[Object],
  },
  data: () => ({
    PAGE_NAME: "ruleDesignPage",
    scriptValue:'',
    metadataValue:[],
    msgValue:'',
    resultValue:'',
    runLoading:false,
  }),
  computed: {
    metadataOptions(){
      const table = {
        height:'260px',
        rowStyle: { height: "32px" },
      }
      return {table}
    },
    metaDataColumn() {
      const key = { label: this.lang.key, prop: "key",form:{} };
      const val = { label: this.lang.value, prop: "val",form:{} };
      return [key, val];
    },
    scriptTip(){
      return `function ${this.functionName} (${this.argNames.join(',')}) `
    },
    apiData(){
      const functionName = this.functionName;
      const argNames = this.argNames;
      const msgType = this.msgType;
      const scriptType = this.scriptType;
      //
      const msg = this.msgValue;
      const metadata = this.parseMetadata();
      const script = this.scriptValue;
      //
      return {
        functionName,
        argNames,
        msgType,
        scriptType,
        msg,
        metadata,
        script
      }
    }
  },
  methods:{
    async testScript(){
      this.runLoading = true;
      try{
        const res = await testScript(this.apiData);
        //
        if(res.output){
          this.resultValue = res.output
        }
        if(res.error){
          this.$message.error(res.error)
        }
      }
      catch(e){console.error(e)}
      this.runLoading = false;
    },
    //
    handleRunTest(){
      this.testScript();
    },
    handleClose(){
      this.$emit('close')
    },
    handleSave(){
      if(this.formElem){
        this.formElem.handleInput(this.scriptValue);
        this.handleClose();
      }
    },
    formatMetadata(){
      if(this.metadata){
        if(Array.isArray(this.metadata)){
          this.metadataValue = [...this.metadata]
        }else if(typeof this.metadata === 'object'){
          let res = []
          Object.keys(this.metadata).forEach(k => {
            const v = this.metadata[k];
            res.push({key:k,val:v})
          })
          this.metadataValue = res;
        }
      }
    },
    parseMetadata(){
      const v = this.metadataValue;
      const res = {};
      v.forEach(({key,val}) => {
        if(key && val){
          res[key] = val;
        }
      })
      return res;
    },
    //
    formatMsg(){
      // console.log(typeof this.msg,this.msg)
      if(typeof this.msg == 'object'){
        this.msgValue = JSON.stringify(this.msg)
      }
      else if(typeof this.msg == 'string'){
        this.msgValue = this.msg;
      }
    },
  },
  watch:{
    metadata(){
      this.formatMetadata();
    },
    msg(){
      this.formatMsg();
    },
    script(v){
      this.scriptValue = v;
    }
  },
  mounted(){
    this.scriptValue = this.script || ''
    this.formatMetadata();
    this.formatMsg();
    //

  }

};
</script>

<style lang="scss" scoped>
.fun-test {
  width: 100%;
  height: 100%;
  display:flex;
  flex-direction: column;
  .ft--flex {
    display: flex;
    border-bottom:1px solid #ddd;
    .ft--flex-item {
      flex: 1;
      padding: 10px;
      display:flex;
      flex-direction: column;
      box-sizing: border-box;
      height:100%;
      overflow:hidden;
      // overflow-y: auto;
      // overflow-x:hidden;
      .els-monaco-editor{
        border:1px solid #ddd;
      }
      .ff--title{
        margin-bottom:10px;
        span{
          margin-right:8px;
        }
      }
      .ff--main{
        // flex:1;
        height:calc(100% - 30px);
        overflow: hidden;
        border-radius: 8px;
      }
    }
  }
  .ft--one {
    height: 300px;
  }
  .ft--two {
    flex:1;
    min-height: 300px;
  }
  .ft--footer{
    margin-top:20px;
    display:flex;
    justify-content: space-between;
  }
}
</style>
