import {getTenantDict,searchTenantDict} from "@/api/manager/rules-manager.js"
import {formatJson} from "@/utils/formats.js"
import {tree as mechanismTypeOptions} from "@/assets/json/tree.js"
import {get,has} from "loadsh"
import i18n from "../../../i18n"

// 语言切换
const lan = i18n.messages[i18n.locale];
const $t = lan.RULE_NODES
//
const handleGetTenantDict = async (n,s) => {
  const d = n ? await getTenantDict(n) : await searchTenantDict(s);
  if(d.data && d.data.length > 0){
    const dict = d.data.map(item => ({label:item.name,value:item.id.id}));
    return dict;
  }
  return [];
}
//通用 节点style
const nodeStyle = {color: "#fff", border: "2px solid"};
//
const nodeModel = {
    type: "mike-modelRect",
    size: [160, 40],
    anchorPoints: [
        [0.5, 1],
        [1, 0.5],
        [0.5, 0],
        [0, 0.5],
    ],
    stateIcon: {
        show: false,
    },
    preRect: {
        show: false,
    },
    labelCfg: {
        style: {fontSize: 10},
    },
    descriptionCfg: {
        paddingTop: -5,
        style: {fontSize: 12, fill: "#000000"},
    },
};
// 通用节点 model style
const nodeModelStyle = {cursor: "move", radius: 5, lineDash: [1, 0]};
//
const transObjOrArray = (v) => {
    if(!v) return v;
    if(Array.isArray(v)){
        let res = {};
        v.forEach(c => {
            if(c.key != undefined){
                res[c.key] = c.value
            }
        })
        return res;
    }
    if(typeof v === 'object'){
        return Object.keys(v).map(k => ({key:k,value:v[k]}))
    }
    return v;
}

const transArray = (v,key) => {
    if(!v || !Array.isArray(v)) return v;
    return v.map(e => {
        if(typeof e == 'object'){
            return e[key]
        }else{
            const r = {};
            r[key] = e
            return r
        }
    })
}

// 通用表单配置项
const formElemConfig = {
    FILTER: {
        script: {
            jsScript: (v, d, e) => ({
                tag: "div",
                class: "els-form--item",
                hooks:{created(){this.context.jsScript = {}}},
                cls: [

                    {
                        tag: "p",
                        class: "editor--tip",
                        cls: [{tag:'b',class:'form-item--required',cls:'*',},"function Filter(msg, metadata, msgType) {"],
                    },
                    {
                        message: lan['ruleDesignPage']['JSScript'],
                        tag: "els-monaco-editor",
                        prop: "jsScript",
                        required:true,
                        value: v,
                        showHeader: false,
                        theme: "vs-light",
                        hooks: {
                            created() {
                                this.context.jsScript.elem = this;
                            }
                        }
                    },
                    {tag: "p", class: "editor--tip", cls: "}"},
                    {
                        tag: 'el-button',
                        cls: 'function test',
                        type: 'primary',
                        size: 'small',
                        icon: 'el-icon-s-tools',
                        hooks: {
                            context(ctx) {
                                ctx.on = {
                                    click: () => {
                                        e.showFunTest('FILTER.script.jsScript', this.context.jsScript.elem.$('value'), this.context.jsScript.elem);
                                    }
                                }
                            }
                        }
                    }
                ],
            }),
        },
        "check alarm status": {
            alarmStatusList: (v) => ({
                tag: "el-select",
                prop: "alarmStatusList",
                label: "Alarm statuses filter",
                style: "min-width:28%;",
                required:true,
                multiple: true,
                cls: dicts.ALARM_STATUS,
                value: v,
            }),
        },
        "gps geofencing filter": {
            fetchPerimeterInfoFromMessageMetadata: (v, d) => ({
                tag: 'el-checkbox',
                prop: 'fetchPerimeterInfoFromMessageMetadata',
                value: v,
                cls: 'Fetch perimeter information from message metadata',
                hooks: {
                    updated() {
                        this.context.source.perimeterType.$("vif", !this.$("value"));
                        // if(this.$("value")) {
                        //     this.context.source.centerLatitude.handleInput(''); //清空之前填入的值
                        // }
                    },
                }
            }),
            perimeterType: (v) => ({
                tag: 'el-select',
                prop: 'perimeterType',
                value: v,
                label: 'Perimeter type',
                cls: dicts.PERIMETER_TYPE,
                hooks: {
                    created() {
                        this.context.source.perimeterType = this;
                        this.$(
                            "vif",
                            !this.context.source.formData.configuration.fetchPerimeterInfoFromMessageMetadata
                        );
                    },
                    updated() {
                        const fetch = this.context.source.formData.configuration.fetchPerimeterInfoFromMessageMetadata;
                        this.context.source.centerLatitude.$("vif", this.$("value") === "CIRCLE" && !fetch);
                        this.context.source.centerLongitude.$("vif", this.$("value") === "CIRCLE" && !fetch);
                        this.context.source.range.$("vif", this.$("value") === "CIRCLE" && !fetch);
                        this.context.source.rangeUnit.$("vif", this.$("value") === "CIRCLE" && !fetch);
                        this.context.source.polygonsDefinition.$("vif", this.$("value") === "POLYGON" && !fetch);
                    },
                }
            }),
            centerLatitude: (v) => ({
                tag: 'el-input-number',
                prop: 'centerLatitude',
                label: 'Center latitude',
                step: 0.1,
                value: v || 0,
                hooks: {
                    created() {
                        this.context.source.centerLatitude = this;
                        this.$(
                            "vif",
                            this.context.source.formData.configuration.perimeterType === "CIRCLE" && !this.context.source.formData.configuration.fetchPerimeterInfoFromMessageMetadata
                        );
                    }
                }
            }),
            centerLongitude: (v) => ({
                tag: 'el-input-number',
                prop: 'centerLongitude',
                label: 'Center longitude',
                value: v || 0,
                hooks: {
                    created() {
                        this.context.source.centerLongitude = this;
                        this.$(
                            "vif",
                            this.context.source.formData.configuration.perimeterType === "CIRCLE" && !this.context.source.formData.configuration.fetchPerimeterInfoFromMessageMetadata
                        );
                    }
                }
            }),
            range: (v) => ({
                tag: 'el-input-number',
                prop: 'range',
                label: 'Range',
                value: v || 0,
                step: 0.1,
                min: 0,
                hooks: {
                    created() {
                        this.context.source.range = this;
                        this.$(
                            "vif",
                            this.context.source.formData.configuration.perimeterType === "CIRCLE" && !this.context.source.formData.configuration.fetchPerimeterInfoFromMessageMetadata
                        );
                    }
                }
            }),
            rangeUnit: (v) => ({
                tag: 'el-select',
                prop: 'rangeUnit',
                label: 'Range unit',
                value: v,
                cls: dicts.RANGE_UNIT,
                hooks: {
                    created() {
                        this.context.source.rangeUnit = this;
                        this.$(
                            "vif",
                            this.context.source.formData.configuration.perimeterType === "CIRCLE" && !this.context.source.formData.configuration.fetchPerimeterInfoFromMessageMetadata
                        );
                    }
                }
            }),
            polygonsDefinition: (v) => ({
                tag: 'el-input',
                prop: 'polygonsDefinition',
                label: 'Polygons definition',
                value: v,
                hooks: {
                    created() {
                        this.context.source.polygonsDefinition = this;
                        this.$(
                            "vif",
                            this.context.source.formData.configuration.perimeterType === "POLYGON" && !this.context.source.formData.configuration.fetchPerimeterInfoFromMessageMetadata
                        );
                    }
                }
            }),
        },
        "message type": {
            messageTypes: (v) => ({
                tag: "el-select",
                prop: "messageTypes",
                label: "messageTypes",
                multiple: true,
                cls: dicts.MESSAGE_TYPES,
                value: v,
            }),
        },
        "message type switch": {
            version: (v) => ({
                tag: "el-input",
                prop: "version",
                value: v,
                type: "hidden",
                elFormItem: false
            }),
        },
        "check existence fields": {
            checkAllKeys: (v) => ({
                tag: 'el-checkbox',
                prop: 'checkAllKeys',
                value: v,
                cls: 'Check that all selected keys are present'
            }),
            messageNames:(v) => ({tag:'item-table',prop:'messageNames',label:'Message Names',value:v,showMenu:true,showSaveBtn:false,showPagination:false,format:(v) => transArray(v,'value'),column:[{prop:'value',label:'Message Name',form:{}}]}),
            metadataNames:(v) => ({tag:'item-table',prop:'metadataNames',label:'Metadata Names',value:v,showMenu:true,showSaveBtn:false,showPagination:false,format:(v) => transArray(v,'value'),column:[{prop:'value',label:'Metadata Name',form:{}}]}),

        },
        switch: {
            jsScript: (v, d, e) => ({
                tag: "div",
                class: "els-form--item",
                cls: [
                    {
                        tag: "p",
                        class: "editor--tip",
                        cls: [{tag:'b',class:'form-item--required',cls:'*',},"function Filter(msg, metadata, msgType) {"],
                    },
                    {
                        tag: "els-monaco-editor",
                        prop: "jsScript",
                        value: v,
                        showHeader: false,
                        theme: "vs-light",
                        hooks: {
                            created() {
                                this.context.source.jsScript = this;
                            }
                        }
                    },
                    {tag: "p", class: "editor--tip", cls: "}"},
                    {
                        tag: 'el-button',
                        cls: 'function test',
                        type: 'primary',
                        size: 'small',
                        icon: 'el-icon-s-tools',
                        hooks: {
                            context(ctx) {
                                ctx.on = {
                                    click: () => {
                                        e.showFunTest('FILTER.switch.jsScript', this.context.source.jsScript.$('value'), this.context.source.jsScript);
                                    }
                                }
                            }
                        }
                    }
                ],
            }),
        },
        "originator type": {
            originatorTypes: (v) => ({
                tag: "el-select",
                prop: "originatorTypes",
                label: "Originator types filter",
                multiple: true,
                cls: dicts.ORIGINATOR_TYPES,
                value: v,
            }),
        },
        "originator type switch": {
            version: (v) => ({
                tag: "el-input",
                prop: "version",
                label: "version",
                value: v,
                type: "hidden",
                elFormItem: false
            }),
        },
        "check relation": {
            checkForSingleEntity: (v) => ({
                tag: 'el-checkbox',
                prop: 'checkForSingleEntity',
                value: v,
                cls: 'Check relation to specific entity'
            }),
            direction: (v) => ({
                tag: "el-select",
                prop: "direction",
                label: lan['ruleDesignPage']['direction'],
                cls: dicts.DIRECTION,
                value: v,
            }),
            entityType: (v) => ({
                tag: "el-select",
                prop: "entityType",
                label: lan['d']['type'],
                cls: dicts.GENERATOR_TYPES,
                value: v,
                hooks: {
                    updated() {

                    }
                }
            }),
            entityId: (v) => ({
                tag: "el-select",
                prop: "entityId",
                label: "entityId",
                cls: [],
                value: v,
            }),
            relationType: (v) => ({
                tag: "el-select",
                prop: "relationType",
                label: lan['ruleDesignPage']['assType'],
                cls: dicts.RELATION_TYPE,
                value: v,
            }),
        },
    },
    ENRICHMENT: {
        "tenant details": {
            detailsList: (v) => ({
                tag: "el-select",
                prop: "detailsList",
                label: "Select entity details",
                cls: [],
                value: v,
                multiple: true,
            }),
            addToMetadata: (v) => ({
                tag: "div",
                class: "els-form--item",
                cls: [
                    {
                        tag: 'el-checkbox',
                        prop: 'addToMetadata',
                        value: v,
                        cls: 'Add selected details to message metadata'
                    },
                    {
                        tag: "p",
                        class: "editor--tip",
                        cls: "If selected, adds the selected details keys to the message metadata instead of message data."
                    },
                ],
            })
        },
        "originator attributes": {
            tellFailureIfAbsent: (v) => ({
                tag: "div",
                class: "els-form--item",
                cls: [
                    {
                        tag: 'el-checkbox',
                        prop: 'tellFailureIfAbsent',
                        value: v,
                        cls: 'Tell Failure'
                    },
                    {
                        tag: "p",
                        class: "editor--tip",
                        cls: "If at least one selected key doesn't exist the outbound message will report \"Failure\"."
                    },
                ],
            }),
            clientAttributeNames: (v) => ({
                tag: "el-select",
                prop: "clientAttributeNames",
                label: "Client attributes",
                multiple: true,
                filterable: true,
                "allow-create": true,
                "default-first-option": true,
                cls: [],
                value: v,
            }),
            sharedAttributeNames: (v) => ({
                tag: "el-select",
                prop: "sharedAttributeNames",
                label: "Shared attributes",
                multiple: true,
                filterable: true,
                "allow-create": true,
                "default-first-option": true,
                cls: [],
                value: v,
            }),
            serverAttributeNames: (v) => ({
                tag: "el-select",
                prop: "serverAttributeNames",
                label: "Server attributes",
                multiple: true,
                filterable: true,
                "allow-create": true,
                "default-first-option": true,
                cls: [],
                value: v,
            }),
            latestTsKeyNames: (v) => ({
                tag: "el-select",
                prop: "latestTsKeyNames",
                label: "Latest timeseries",
                multiple: true,
                filterable: true,
                "allow-create": true,
                "default-first-option": true,
                cls: [],
                value: v,
            }),
            getLatestValueWithTs: (v) => ({
                tag: "div",
                class: "els-form--item",
                cls: [
                    {
                        tag: 'el-checkbox',
                        prop: 'getLatestValueWithTs',
                        value: v,
                        cls: 'Fetch Latest telemetry with Timestamp'
                    },
                    {
                        tag: "p",
                        class: "editor--tip",
                        cls: "If selected, latest telemetry values will be added to the outbound message metadata with timestamp, e.g: \"temp\": \"{\\\"ts\\\":1574329385897,\\\"value\\\":42}\""
                    },
                ],
            }),
        },
        "originator telemetry": {
            latestTsKeyNames: (v) => ({
                tag: "el-select",
                prop: "latestTsKeyNames",
                label: "Latest timeseries",
                multiple: true,
                filterable: true,
                "allow-create": true,
                "default-first-option": true,
                cls: [],
                value: v,
            }),
            fetchMode: (v) => ({
                tag: "el-select",
                prop: "fetchMode",
                label: "Fetch mode",
                cls: dicts.FETCH_MODE,
                value: v,
            }),
            useMetadataIntervalPatterns: (v) => ({
                tag: 'el-checkbox',
                prop: 'useMetadataIntervalPatterns',
                value: v,
                cls: 'Use metadata interval patterns',
                hooks: {
                    updated(v) {
                        this.context.source.startIntervalPattern.$("vif", this.$("value"));
                        this.context.source.endIntervalPattern.$("vif", this.$("value"));
                        this.context.source.startInterval.$("vif", !this.$("value"));
                        this.context.source.startIntervalTimeUnit.$("vif", !this.$("value"));
                        this.context.source.endInterval.$("vif", !this.$("value"));
                        this.context.source.endIntervalTimeUnit.$("vif", !this.$("value"));
                    }
                }
            }),
            startIntervalPattern: (v) => ({
                tag: "div",
                class: "els-form--item",
                cls: [
                    {
                        tag: 'el-input',
                        prop: 'startIntervalPattern',
                        value: v,
                        label: "Start interval pattern",
                    },
                    {
                        tag: "p",
                        class: "editor--tip",
                        cls: "Start interval pattern, use ${metaKeyName} to substitute variables from metadata"
                    },
                ],
                hooks: {
                    created() {
                        this.context.source.startIntervalPattern = this;
                        this.$(
                            "vif",
                            this.context.source.formData.configuration.useMetadataIntervalPatterns
                        );
                    }
                }
            }),
            endIntervalPattern: (v) => ({
                tag: "div",
                class: "els-form--item",
                cls: [
                    {
                        tag: 'el-input',
                        prop: 'endIntervalPattern',
                        value: v,
                        label: "End interval pattern",
                    },
                    {
                        tag: "p",
                        class: "editor--tip",
                        cls: "End interval pattern, use ${metaKeyName} to substitute variables from metadata"
                    }
                ],
                hooks: {
                    created() {
                        this.context.source.endIntervalPattern = this;
                        this.$(
                            "vif",
                            this.context.source.formData.configuration.useMetadataIntervalPatterns
                        );
                    }
                }
            }),
            startInterval: (v) => ({
                tag: 'el-input-number',
                prop: 'startInterval',
                value: v,
                min: 1,
                label: "Start interval",
                hooks: {
                    created() {
                        this.context.source.startInterval = this;
                        this.$(
                            "vif",
                            !this.context.source.formData.configuration.useMetadataIntervalPatterns
                        );
                    }
                }
            }),
            startIntervalTimeUnit: (v) => ({
                tag: "el-select",
                prop: "startIntervalTimeUnit",
                label: "Start interval time unit",
                cls: dicts.TIME_UNIT,
                value: v,
                hooks: {
                    created() {
                        this.context.source.startIntervalTimeUnit = this;
                        this.$(
                            "vif",
                            !this.context.source.formData.configuration.useMetadataIntervalPatterns
                        );
                    }
                }
            }),
            endInterval: (v) => ({
                tag: 'el-input-number',
                prop: 'endInterval',
                value: v,
                min: 1,
                label: "End interval",
                hooks: {
                    created() {
                        this.context.source.endInterval = this;
                        this.$(
                            "vif",
                            !this.context.source.formData.configuration.useMetadataIntervalPatterns
                        );
                    }
                }
            }),
            endIntervalTimeUnit: (v) => ({
                tag: "el-select",
                prop: "endIntervalTimeUnit",
                label: "End interval time unit",
                cls: dicts.TIME_UNIT,
                value: v,
                hooks: {
                    created() {
                        this.context.source.endIntervalTimeUnit = this;
                        this.$(
                            "vif",
                            !this.context.source.formData.configuration.useMetadataIntervalPatterns
                        );
                    }
                }
            }),
            orderBy: (v) => ({
                tag: 'el-input',
                prop: 'orderBy',
                value: v,
                type: "hidden",
                elFormItem: false
            }),
            limit: (v) => ({
                tag: 'el-input',
                prop: 'orderBy',
                value: 1000,
                type: "hidden",
                elFormItem: false
            }),
        },
        "customer details": {
            detailsList: (v) => ({
                tag: "el-select",
                prop: "detailsList",
                label: "Select entity details",
                multiple: true,
                cls: [],
                value: v,
            }),
            addToMetadata: (v) => ({
                tag: "div",
                class: "els-form--item",
                cls: [
                    {
                        tag: 'el-checkbox',
                        prop: 'addToMetadata',
                        value: v,
                        cls: 'Add selected details to message metadata'
                    },
                    {
                        tag: "p",
                        class: "editor--tip",
                        cls: "If selected, adds the selected details keys to the message metadata instead of message data."
                    },
                ],
            }),
        },
        "related attributes":{
            relationsQuery:(v = {}) => ({tag:'els-form-group',prop:'relationsQuery',title:'Relations query',cls:[
                {tag:'el-checkbox',prop:'fetchLastLevelOnly',cls:' Fetch last level relation only ',value:v.fetchLastLevelOnly},
                {tag:'el-select',elFormItem:{class:'inline'},prop:'direction',label:lan['ruleDesignPage']['dirFrom'],value:v.direction,cls:dicts.DIRECTION},
                {tag:'el-input-number',elFormItem:{class:'inline'},prop:'maxLevel',label:'Max relation level',min:1,value:v.maxLevel},
                {tag:'item-table',prop:'filters',label:lan['ruleDesignPage']['assFilter'],value:v.filters,showMenu:true,showSaveBtn:false,showPagination:false,column:[{prop:'relationType',label:lan['d']['type'],form:{}},{prop:'entityTypes',label:lan['ruleDesignPage']['entityType'],form:{tag:'el-select',multiple:true,cls:dicts.ORIGINATOR_TYPES}}]}
            ]}),
            telemetry:(v) => ({tag:'el-checkbox',prop:'telemetry',cls:'Latest telemetry ',value:v}),
            attrMapping:(v) => ({tag:'item-table',prop:'attrMapping',label:'Attributes mapping',value:v,showMenu:true,showSaveBtn:false,showPagination:false,column:[{prop:'key',label:'Source attribute / telemetry',form:{}},{prop:'value',label:'Target attribute',form:{}}],format:transObjOrArray}),

        },
        "originator fields":{
            fieldsMapping:(v) => ({tag:'item-table',prop:'fieldsMapping',label:'Fields mapping',value:v,showMenu:true,showSaveBtn:false,showPagination:false,column:[{prop:'key',label:'Source field',form:{}},{prop:'value',label:'Target attribute',form:{}}],format:transObjOrArray}),
        },
    },
    ACTION: {
        "synchronization start": {
            version: (v) => ({
                tag: 'el-input',
                prop: 'version',
                value: 0,
                type: "hidden",
                elFormItem: false
            }),
        },
        "接收数据记录": {
            version: (v) => ({
                tag: 'el-input',
                prop: 'version',
                value: 0,
                type: "hidden",
                elFormItem: false
            }),
        },
        "save timeseries": {
            defaultTTL: (v) => ({
                tag: "el-input-number",
                prop: "defaultTTL",
                label: "Default TTL in seconds",
                min: 0,
                value: v,
            }),
        },
        'save attributes': {
            scope: (v) => ({
                tag: 'el-select', label: lan['ruleDesignPage']['deviceRange'], prop: 'scope', value: v, cls: dicts.ATTRIBUTES_TYPES, hooks: {
                    updated() {
                        const b = this.$('value') === 'SHARED_SCOPE';
                        this.context.source.notifyDevice.$('vif', b)
                    }
                }
            }),
            notifyDevice: (v) => ({
                tag: 'div', class: 'els-form--item', value: v, hooks: {
                    created() {
                        this.context.source.notifyDevice = this;
                        this.$('vif', this.context.source.formData.configuration.scope == 'SHARED_SCOPE')
                    }
                }, cls: [
                    {tag: 'el-checkbox', cls: 'Notify Device ', prop: 'notifyDevice',},
                    {
                        tag: 'i',
                        class: 'editor--tip',
                        cls: 'If the message arrives from the device, we will push it back to the device by default.'
                    }
                ]
            })
        },
        "delay": {
            "useMetadataPeriodInSecondsPatterns": (v) => ({
                tag: 'el-checkbox',
                prop: 'useMetadataPeriodInSecondsPatterns',
                value: v,
                cls: 'Use metadata period in seconds pattern',
                hooks: {
                    updated(v) {
                        this.context.source.periodInSecondsPattern.$("vif", this.$("value"));
                        this.context.source.periodInSeconds.$("vif", !this.$("value"));
                    }
                }
            }),
            "periodInSeconds": (v) => ({
                tag: 'el-input-number',
                prop: 'periodInSeconds',
                value: v,
                min: 0,
                label: "Period in seconds",
                hooks: {
                    created() {
                        this.context.source.periodInSeconds = this;
                        this.$(
                            "vif",
                            !this.context.source.formData.configuration.useMetadataPeriodInSecondsPatterns
                        );
                    }
                }
            }),
            "periodInSecondsPattern": (v) => ({
                tag: "div",
                class: "els-form--item",
                cls: [
                    {
                        tag: 'el-input',
                        prop: 'periodInSecondsPattern',
                        value: v,
                        label: "Period in seconds metadata pattern",
                    },
                    {
                        tag: "p",
                        class: "editor--tip",
                        cls: "Period in seconds pattern, use ${metaKeyName} to substitute variables from metadata"
                    },
                ],
                hooks: {
                    created() {
                        this.context.source.periodInSecondsPattern = this;
                        this.$(
                            "vif", this.context.source.formData.configuration.useMetadataPeriodInSecondsPatterns
                        );
                    }
                }
            }),
            "maxPendingMsgs": (v) => ({
                tag: 'el-input-number',
                prop: 'maxPendingMsgs',
                value: v,
                min: 1,
                max: 100000,
                label: "Maximum pending messages",
            })
        },
        "create alarm": {
            alarmDetailsBuildJs: (v, d, e) => ({
                tag: "div",
                class: "els-form--item",
                cls: [
                    {
                        tag: "p",
                        cls: "Alarm details builder",
                    },
                    {
                        tag: "p",
                        class: "editor--tip",
                        cls: "function Details(msg, metadata, msgType) {",
                    },
                    {
                        tag: "els-monaco-editor",
                        prop: "alarmDetailsBuildJs",
                        value: v,
                        showHeader: false,
                        theme: "vs-light",
                        hooks: {
                            created() {
                                this.context.source.alarmDetailsBuildJs = this;
                            }
                        }
                    },
                    {tag: "p", class: "editor--tip", cls: "}"},
                    {
                        tag: 'el-button',
                        cls: 'function test',
                        type: 'primary',
                        size: 'small',
                        icon: 'el-icon-s-tools',
                        hooks: {
                            context(ctx) {
                                ctx.on = {
                                    click: () => {
                                        e.showFunTest('ACTION.create alarm.alarmDetailsBuildJs', this.context.source.alarmDetailsBuildJs.$('value'), this.context.source.alarmDetailsBuildJs);
                                    }
                                }
                            }
                        }
                    }
                ],
            }),
            "useMessageAlarmData": (v) => ({
                tag: 'el-checkbox',
                prop: 'useMessageAlarmData',
                value: v,
                cls: 'Use message alarm data',
                hooks: {
                    updated(v) {
                        this.context.source.alarmType.$("vif", !this.$("value"));
                        this.context.source.severity.$("vif", !this.$("value"));
                        this.context.source.propagate.$("vif", !this.$("value"));
                    }
                }
            }),
            "alarmType": (v) => ({
                tag: 'el-input',
                prop: 'alarmType',
                value: v,
                label: "Alarm type",
                hooks: {
                    created() {
                        this.context.source.alarmType = this;
                        this.$(
                            "vif", !this.context.source.formData.configuration.useMessageAlarmData
                        );
                    }
                }
            }),
            "severity": (v) => ({
                tag: "el-select",
                prop: "severity",
                label: lan['ruleDesignPage']['direction'],
                value: v,
                cls: dicts.SEVERITY,
                hooks: {
                    created() {
                        this.context.source.severity = this;
                        this.$(
                            "vif", !this.context.source.formData.configuration.useMessageAlarmData
                        );
                    }
                }
            }),
            "propagate": (v) => ({
                tag: 'el-checkbox',
                prop: 'propagate',
                value: v,
                cls: 'Propagate',
                hooks: {
                    created() {
                        this.context.source.propagate = this;
                        this.$(
                            "vif", !this.context.source.formData.configuration.useMessageAlarmData
                        );
                    },
                    updated(v) {
                        this.context.source.relationTypes.$("vif", this.$("value") && !this.context.source.formData.configuration.useMessageAlarmData);
                    }
                }
            }),
            "relationTypes": (v) => ({
                tag: "el-select",
                prop: "relationTypes",
                label: "Relation types to propagate",
                multiple: true,
                filterable: true,
                "allow-create": true,
                "default-first-option": true,
                cls: [],
                value: v,
                hooks: {
                    created() {
                        this.context.source.relationTypes = this;
                        this.$(
                            "vif", this.context.source.formData.configuration.propagate && !this.context.source.formData.configuration.useMessageAlarmData
                        );
                    }
                }
            }),
        },
        "xm2m协议解析": {
            version: (v) => ({
                tag: 'el-input',
                prop: 'version',
                value: 0,
                type: "hidden",
                elFormItem: false
            }),
        },
        log: {
            jsScript: (v, d, e) => ({
                tag: "div",
                class: "els-form--item",
                cls: [
                    {
                        tag: "p",
                        class: "editor--tip",
                        cls: [{tag:'b',class:'form-item--required',cls:'*',},"function ToString(msg, metadata, msgType) {"],
                    },
                    {
                        tag: "els-monaco-editor",
                        prop: "jsScript",
                        value: v,
                        showHeader: false,
                        theme: "vs-light",
                        hooks: {
                            created() {
                                this.context.source.jsScript = this;
                            }
                        }
                    },
                    {tag: "p", class: "editor--tip", cls: "}"},
                    {
                        tag: 'el-button',
                        cls: 'function test',
                        type: 'primary',
                        size: 'small',
                        icon: 'el-icon-s-tools',
                        hooks: {
                            context(ctx) {
                                ctx.on = {
                                    click: () => {
                                        e.showFunTest('ACTION.log.jsScript', this.context.source.jsScript.$('value'), this.context.source.jsScript);
                                    }
                                }
                            }
                        }
                    }
                ],
            }),
        },
        "unassign from customer": {
            customerCacheExpiration: (v) => ({
                tag: 'el-input-number',
                prop: 'customerCacheExpiration',
                value: v,
                min: 1,
                label: "Customers cache expiration time(sec)",
            })
        },
        "copy to view": {
            version: (v) => ({
                tag: 'el-input',
                prop: 'version',
                value: 0,
                type: "hidden",
                elFormItem: false
            }),
        },
        "clear alarm": {
            alarmDetailsBuildJs: (v, d, e) => ({
                tag: "div",
                class: "els-form--item",
                cls: [
                    {
                        tag: "p",
                        cls: "Alarm details builder",
                    },
                    {
                        tag: "p",
                        class: "editor--tip",
                        cls: "function Details(msg, metadata, msgType) {",
                    },
                    {
                        tag: "els-monaco-editor",
                        prop: "alarmDetailsBuildJs",
                        value: v,
                        showHeader: false,
                        theme: "vs-light",
                        hooks: {
                            created() {
                                this.context.source.alarmDetailsBuildJs = this;
                            }
                        }
                    },
                    {tag: "p", class: "editor--tip", cls: "}"},
                    {
                        tag: 'el-button',
                        cls: 'function test',
                        type: 'primary',
                        size: 'small',
                        icon: 'el-icon-s-tools',
                        hooks: {
                            context(ctx) {
                                ctx.on = {
                                    click: () => {
                                        e.showFunTest('ACTION.clear alarm.alarmDetailsBuildJs', this.context.source.alarmDetailsBuildJs.$('value'), this.context.source.alarmDetailsBuildJs);
                                    }
                                }
                            }
                        }
                    }
                ],
            }),
        },
        "save to custom table": {
            fieldsMapping: (v) => ({
                tag: "item-table",
                prop: "fieldsMapping",
                value: v,
                label: "Fields mapping",
                showPagination: false,
                showSaveBtn:false,
                format: (v) => {
                    const isArray = Array.isArray(v);
                    if (isArray) {
                        // array 转 obj
                        const o = {};
                        v.forEach((l) => {
                            const {key, value} = l;
                            o[key] = value;
                        });
                        return o;
                    } else {
                        const l = [];
                        Object.keys(v).forEach((k) => {
                            const d = {key: k, value: v[k]};
                            l.push(d);
                        });
                        return l;
                    }
                },
                column: [
                    {prop: "key", label: "Message field", form: {}},
                    {prop: "value", label: "Table column", form: {}},
                ],
            }),
        },
        "checkpoint": {
            queueName: (v) => ({
                tag: "el-select",
                prop: "queueName",
                label: "Queue name",
                filterable: true,
                "allow-create": true,
                "default-first-option": true,
                cls: [{value: "HighPriority"}, {value: "Main"}, {value: "SequentialByOriginator"}],
                value: v,
            })
        },
        "记录设备上下线": {
            version: (v) => ({
                tag: 'el-input',
                prop: 'version',
                value: 0,
                type: "hidden",
                elFormItem: false
            }),
        },
        "gps geofencing events": {
            fetchPerimeterInfoFromMessageMetadata: (v) => ({
                tag: "el-checkbox",
                prop: "fetchPerimeterInfoFromMessageMetadata",
                value: v,
                cls: "Fetch perimeter information from message metadata",
                hooks: {
                    updated() {
                        this.context.source.perimeterType.$("vif", !this.$("value"));
                    },
                },
            }),
            perimeterType: (v) => ({
                tag: 'el-select',
                prop: 'perimeterType',
                value: v,
                label: 'Perimeter type',
                cls: dicts.PERIMETER_TYPE,
                hooks: {
                    created() {
                        this.context.source.perimeterType = this;
                        this.$(
                            "vif",
                            !this.context.source.formData.configuration.fetchPerimeterInfoFromMessageMetadata
                        );
                    },
                    updated() {
                        const fetch = this.context.source.formData.configuration.fetchPerimeterInfoFromMessageMetadata;
                        this.context.source.centerLatitude.$("vif", this.$("value") === "CIRCLE" && !fetch);
                        this.context.source.centerLongitude.$("vif", this.$("value") === "CIRCLE" && !fetch);
                        this.context.source.range.$("vif", this.$("value") === "CIRCLE" && !fetch);
                        this.context.source.rangeUnit.$("vif", this.$("value") === "CIRCLE" && !fetch);
                        this.context.source.polygonsDefinition.$("vif", this.$("value") === "POLYGON" && !fetch);
                    },
                }
            }),
            centerLatitude: (v) => ({
                tag: 'el-input-number',
                prop: 'centerLatitude',
                label: 'Center latitude',
                step: 0.1,
                value: v || 0,
                hooks: {
                    created() {
                        this.context.source.centerLatitude = this;
                        this.$(
                            "vif",
                            this.context.source.formData.configuration.perimeterType === "CIRCLE" && !this.context.source.formData.configuration.fetchPerimeterInfoFromMessageMetadata
                        );
                    }
                }
            }),
            centerLongitude: (v) => ({
                tag: 'el-input-number',
                prop: 'centerLongitude',
                label: 'Center longitude',
                value: v || 0,
                hooks: {
                    created() {
                        this.context.source.centerLongitude = this;
                        this.$(
                            "vif",
                            this.context.source.formData.configuration.perimeterType === "CIRCLE" && !this.context.source.formData.configuration.fetchPerimeterInfoFromMessageMetadata
                        );
                    }
                }
            }),
            range: (v) => ({
                tag: 'el-input-number',
                prop: 'range',
                label: 'Range',
                value: v || 0,
                step: 0.1,
                min: 0,
                hooks: {
                    created() {
                        this.context.source.range = this;
                        this.$(
                            "vif",
                            this.context.source.formData.configuration.perimeterType === "CIRCLE" && !this.context.source.formData.configuration.fetchPerimeterInfoFromMessageMetadata
                        );
                    }
                }
            }),
            rangeUnit: (v) => ({
                tag: 'el-select',
                prop: 'rangeUnit',
                label: 'Range unit',
                value: v,
                cls: dicts.RANGE_UNIT,
                hooks: {
                    created() {
                        this.context.source.rangeUnit = this;
                        this.$(
                            "vif",
                            this.context.source.formData.configuration.perimeterType === "CIRCLE" && !this.context.source.formData.configuration.fetchPerimeterInfoFromMessageMetadata
                        );
                    }
                }
            }),
            polygonsDefinition: (v) => ({
                tag: 'el-input',
                prop: 'polygonsDefinition',
                label: 'Polygons definition',
                value: v,
                hooks: {
                    created() {
                        this.context.source.polygonsDefinition = this;
                        this.$(
                            "vif",
                            this.context.source.formData.configuration.perimeterType === "POLYGON" && !this.context.source.formData.configuration.fetchPerimeterInfoFromMessageMetadata
                        );
                    }
                }
            }),
            minInsideDuration: (v) => ({
                tag: 'el-input-number',
                prop: 'minInsideDuration',
                label: 'Minimal inside duration',
                value: v,
                min: 1,
            }),
            minOutsideDuration: (v) => ({
                tag: 'el-input-number',
                prop: 'minOutsideDuration',
                label: 'Minimal outside duration',
                value: v,
                min: 1,
            }),
            minInsideDurationTimeUnit: (v) => ({
                tag: 'el-select',
                prop: 'minInsideDurationTimeUnit',
                label: 'Minimal inside duration time unit',
                value: v,
                cls: dicts.TIME_UNIT,
            }),
            minOutsideDurationTimeUnit: (v) => ({
                tag: 'el-select',
                prop: 'minOutsideDurationTimeUnit',
                label: 'Minimal outside duration time unit',
                value: v,
                cls: dicts.TIME_UNIT,
            }),
        },
        "离线下发": {
            version: (v) => ({
                tag: 'el-input',
                prop: 'version',
                value: 0,
                type: "hidden",
                elFormItem: false
            }),
        },
        "rpc call request": {
            timeoutInSeconds: (v) => ({
                tag: 'el-input-number',
                prop: 'timeoutInSeconds',
                label: 'Timeout in seconds',
                value: v,
                min: 0,
            }),
        },
        "rpc call reply":{
          serviceIdMetaDataAttribute:(v)=>({tag:'el-input',type:'hidden',value:v,elFormItem:false,prop:'serviceIdMetaDataAttribute'}),
          sessionIdMetaDataAttribute:(v)=>({tag:'el-input',type:'hidden',value:v,elFormItem:false,prop:'sessionIdMetaDataAttribute'}),
          requestIdMetaDataAttribute:(v)=>({tag:'el-input',value:v,label:'Request Id Metadata attribute name',prop:'requestIdMetaDataAttribute'}),
        },
        "message count": {
            interval: (v) => ({
                tag: 'el-input-number',
                prop: 'interval',
                label: 'Interval in seconds',
                value: v,
                min: 1,
            }),
        },
        "acknowledge": {
            version: (v) => ({
                tag: 'el-input',
                prop: 'version',
                value: 0,
                type: "hidden",
                elFormItem: false
            }),
        },
        "synchronization end": {
            version: (v) => ({
                tag: 'el-input',
                prop: 'version',
                value: 0,
                type: "hidden",
                elFormItem: false
            }),
        },
        "assign to customer": {
            createCustomerIfNotExists: (v) => ({
                tag: "el-checkbox",
                prop: "createCustomerIfNotExists",
                value: v,
                cls: "Create new customer if not exists",
            }),
            customerCacheExpiration: (v) => ({
                tag: 'el-input-number',
                prop: 'customerCacheExpiration',
                label: 'Customers cache expiration time (sec)',
                value: v,
                min: 0,
            }),
        },
        "delete relation": {
            deleteForSingleEntity: (v) => ({
                tag: "el-checkbox",
                prop: "deleteForSingleEntity",
                value: v,
                cls: "Delete relation to specific entity",
                hooks: {
                    updated(v) {
                        this.context.source.entityType.$("vif", this.$("value"));
                        this.context.source.entityNamePattern.$("vif", this.$("value"));
                    },
                }
            }),
            direction: (v) => ({
                tag: "el-select",
                prop: "direction",
                label: lan['ruleDesignPage']['direction'],
                cls: dicts.DIRECTION,
                value: v,
            }),
            entityType: (v) => ({
                tag: "el-select",
                prop: "entityType",
                label: lan['d']['type'],
                cls: dicts.GENERATOR_TYPES,
                value: v,
                hooks: {
                    created() {
                        this.context.source.entityType = this;
                        this.$(
                            "vif",
                            this.context.source.formData.configuration.deleteForSingleEntity
                        );
                    }
                }
            }),
            entityNamePattern: (v) => ({
                tag: 'el-input',
                prop: 'entityNamePattern',
                label: 'Name pattern',
                value: v,
                hooks: {
                    created() {
                        this.context.source.entityNamePattern = this;
                        this.$(
                            "vif",
                            this.context.source.formData.configuration.deleteForSingleEntity
                        );
                    }
                }
            }),
            entityCacheExpiration: (v) => ({
                tag: 'el-input-number',
                prop: 'entityCacheExpiration',
                label: 'Entities cache expiration time (sec)',
                value: v,
                min: 0,
            }),
        },
        generator:{
          msgCount:(v) => ({tag:'el-input-number',prop:'msgCount',value:v,min:0,label:'Message count (0 - unlimited)'}),
          periodInSeconds:(v) => ({tag:'el-input-number',prop:'periodInSeconds',value:v,min:1,label:'Period in seconds'}),
          originatorType:(v) => ({tag:'el-select',prop:'originatorType',value:v,label:'Originator '+lan['d']['type'],cls:dicts.GENERATOR_TYPES.filter(n => 'DEVICE,ASSET'.includes(n.value)),hooks:{
              created(){
                this.updatedOriginatorType = async (bool = true) => {
                  let v = this.$('value')
                  const e = this.context.source.originatorType
                  if(v && e){
                    bool && e.handleInput('')
                    try{
                      // if(v == 'TENANT'){}
                      const dict = await handleGetTenantDict(v);
                      e.resetDict(dict)
                    }catch(e){console.log(e)}

                  }
                }
              },
              mounted(){
                this.updatedOriginatorType(false);
                const e = this.context.source.originatorType
                if(!this.$('value') && e){
                  e.resetDisabled(true)
                }
              },
              updated(){
                this.updatedOriginatorType();
                const e = this.context.source.originatorType
                if(e){
                  e.resetDisabled(!this.$('value'))
                }
              }
            }
          }),
          originatorId:(v) => ({tag:'el-select',prop:'originatorId',value:v,label:'Originator '+lan['ruleDesignPage']['value'],filterable:true, hooks:{
            created(){this.context.source.originatorType = this;this.resetDict = (d = []) => {this.$('cls',d)};this.resetDisabled = (b = false) => {this.$('disabled',b)}},
            context(ctx){ctx.props.disabled = this.$('disabled')}
            // context(ctx){ctx.props.filterMethod = async (s) => {this.resetDict(await handleGetTenantDict(undefined,s))}}
          }}),
          jsScript:(v, d, e) => ({
            tag: "div",
            class: "els-form--item",
            cls: [
                {
                    tag: "p",
                    class: "editor--tip",
                    cls: [{tag:'b',class:'form-item--required',cls:'*',},"function Generate(msg, metadata, msgType) {"],
                },
                {
                    tag: "els-monaco-editor",
                    prop: "jsScript",
                    value: v,
                    showHeader: false,
                    theme: "vs-light",
                    hooks: {
                        created() {
                            this.context.source.jsScript = this;
                        }
                    }
                },
                {tag: "p", class: "editor--tip", cls: "}"},
                {
                    tag: 'el-button',
                    cls: 'function test',
                    type: 'primary',
                    size: 'small',
                    icon: 'el-icon-s-tools',
                    hooks: {
                        context(ctx) {
                            ctx.on = {
                                click: () => {
                                    e.showFunTest('ACTION.generator.jsScript', this.context.source.jsScript.$('value'), this.context.source.jsScript);
                                }
                            }
                        }
                    }
                }
            ],
        }),
        },
    },
    EXTERNAL: {
        rabbitmq: {
            messageProperties: (v) => ({
                tag: "el-select",
                prop: "messageProperties",
                label: "Message properties",
                value: v,
                cls: [
                    {value: "BASIC"},
                    {value: "TEXT_PLAIN"},
                    {value: "MINIMAL_BASIC"},
                    {value: "MINIMAL_PERSISTENT_BASIC"},
                    {value: "PERSISTENT_BASIC"},
                    {value: "PERSISTENT_TEXT_PLAIN"},
                ],
            }),
            password: (v) => ({
                tag: "el-input",
                prop: "password",
                label: "Password",
                "show-password": true,
                value: v,
            }),
            automaticRecoveryEnabled:(v) => ({
                tag: "el-checkbox",
                prop: "automaticRecoveryEnabled",
                value: v,
                cls: "Automatic recovery",
            }),
            connectionTimeout:(v) => ({
                tag: 'el-input-number',
                prop: 'connectionTimeout',
                label: 'Connection timeout (ms)',
                value: v,
                min: 0,
            }),
            handshakeTimeout:(v) => ({
                tag: 'el-input-number',
                prop: 'handshakeTimeout',
                label: 'Handshake timeout (ms)',
                value: v,
                min: 0,
            }),
            clientProperties: (v) => ({
                tag: "item-table",
                prop: "clientProperties",
                value: v,
                label: "Client properties",
                showPagination: false,
                showSaveBtn:false,
                format: (v) => {
                    const isArray = Array.isArray(v);
                    if (isArray) {
                        // array 转 obj
                        const o = {};
                        v.forEach((l) => {
                            const {key, value} = l;
                            o[key] = value;
                        });
                        return o;
                    } else {
                        const l = [];
                        Object.keys(v).forEach((k) => {
                            const d = {key: k, value: v[k]};
                            l.push(d);
                        });
                        return l;
                    }
                },
                column: [
                    {prop: "key", label: "Key", form: {}},
                    {prop: "value", label: "Value", form: {}},
                ],
            }),
        },
        "gcp pubsub":{
            serviceAccountKey:(v,d) => ({
                tag: "self-upload",
                prop: "serviceAccountKey",
                label: "GCP service account key file",
                value: v,
                accept:"",
                size: "mini",
                resultKey:"url",
                hooks: {
                    created() {
                        const value = this.context.source.formData.configuration.serviceAccountKey;
                        if(value) {
                            this.context.source.formData.configuration.serviceAccountKeyFileName = value.substring(value.indexOf("-") + 1);
                        }
                    },
                    updated() {
                        this.context.source.formData.configuration.serviceAccountKeyFileName = this.$("value").substring(this.$("value").indexOf("-") + 1)
                    },
                    context(ctx) {
                        const value = this.$("value");
                        const name = value.substring(value.indexOf("-") + 1);
                        ctx.props.fileList=[{name,url:value}];
                    }
                }
                }),
            serviceAccountKeyFileName:(v) => ({
                tag: 'el-input',
                prop: 'serviceAccountKeyFileName',
                type: "hidden",
                elFormItem: false
            }),
            messageAttributes:(v) => ({
                tag: "item-table",
                prop: "messageAttributes",
                value: v,
                label: "Message attributes",
                showPagination: false,
                showSaveBtn:false,
                format: (v) => {
                    const isArray = Array.isArray(v);
                    if (isArray) {
                        // array 转 obj
                        const o = {};
                        v.forEach((l) => {
                            const {key, value} = l;
                            o[key] = value;
                        });
                        return o;
                    } else {
                        const l = [];
                        Object.keys(v).forEach((k) => {
                            const d = {key: k, value: v[k]};
                            l.push(d);
                        });
                        return l;
                    }
                },
                column: [
                    {prop: "key", label: "Key", form: {}},
                    {prop: "value", label: "Value", form: {}},
                ],
            }),
        },
        kafka: {
            bootstrapServers: (v) => ({
                tag: "el-input",
                prop: "bootstrapServers",
                label: "Bootstrap servers",
                value: v,
            }),
            retries: (v) => ({
                tag: "el-input-number",
                min:0,
                prop: "retries",
                label: "Automatically retry times if fails",
                value: v,
            }),
            batchSize: (v) => ({
                tag: "el-input-number",
                prop: "batchSize",
                min:0,
                label: "Produces batch size in bytes",
                value: v,
            }),
            bufferMemory: (v) => ({
                tag: "el-input-number",
                prop: "bufferMemory",
                min:0,
                label: "Client buffer max size in bytes",
                value: v,
            }),
            linger:(v) => ({
                tag: "el-input-number",
                prop: "linger",
                label:"Time to buffer locally (ms)",
                min:0,
                value:v,
            }),
            acks: (v) => ({
                tag: "el-select",
                prop: "acks",
                label: "Number of acknowledgments",
                value: v,
                cls: [
                    {value: "all"},
                    {value: "-1"},
                    {value: "0"},
                    {value: "1"},
                ],
            }),
            keySerializer: (v) => ({
                tag: "el-input",
                prop: "keySerializer",
                label: "Key serializer",
                value: v,
            }),
            valueSerializer: (v) => ({
                tag: "el-input",
                prop: "valueSerializer",
                label: "Value serializer",
                value: v,
            }),
            otherProperties: (v) => ({
                tag: "item-table",
                prop: "otherProperties",
                value: v,
                label: "Other properties",
                showPagination: false,
                showSaveBtn:false,
                format: (v) => {
                    const isArray = Array.isArray(v);
                    if (isArray) {
                        // array 转 obj
                        const o = {};
                        v.forEach((l) => {
                            const {key, value} = l;
                            o[key] = value;
                        });
                        return o;
                    } else {
                        const l = [];
                        Object.keys(v).forEach((k) => {
                            const d = {key: k, value: v[k]};
                            l.push(d);
                        });
                        return l;
                    }
                },
                column: [
                    {prop: "key", label: "key", form: {}},
                    {prop: "value", label: "value", form: {}},
                ],
            }),
            addMetadataKeyValuesAsKafkaHeaders: (v, d) => ({
                tag: "div",
                class: "els-form--item",
                hooks: {
                    created() {
                        this.context.charset = {div: this};
                    },
                },
                cls: [
                    {
                        tag: "el-checkbox",
                        prop: "addMetadataKeyValuesAsKafkaHeaders",
                        value: v,
                        cls: "Add Message metadata key-value pairs to Kafka record headers",
                        hooks: {
                            updated() {
                                const b = this.$("value");
                                this.context.charset.target &&
                                this.context.charset.target.resetChild(b);
                            },
                        },
                    },
                    {
                        tag: "p",
                        class: "editor--tip",
                        cls:
                            "If selected, key-value pairs from message metadata will be added to the outgoing records headers as byte arrays with predefined charset encoding.",
                    },
                    {
                        tag: "div",
                        hooks: {
                            created() {
                                this.context.charset.target = this;
                                this.resetChild = (b) => {
                                    const cls = {
                                        tag: "el-select",
                                        prop: "kafkaHeadersCharset",
                                        value: d.kafkaHeadersCharset,
                                        label: "Charset encoding",
                                        cls: dicts.CODES,
                                    };
                                    if (b) {
                                        this.$("cls", cls);
                                    } else {
                                        this.$("cls", "");
                                    }
                                };
                            },
                            mounted() {
                                const b = d.addMetadataKeyValuesAsKafkaHeaders;
                                this.resetChild(b);
                            },
                        },
                    },
                ],
            }),
            kafkaHeadersCharset: () => "",
        },
        "synchronize kafka": {
            bootstrapServers: (v) => ({
                tag: "el-input",
                prop: "bootstrapServers",
                label: "Bootstrap servers",
                value: v,
            }),
            retries: (v) => ({
                tag: "el-input-number",
                min:0,
                prop: "retries",
                label: "Automatically retry times if fails",
                value: v,
            }),
            batchSize: (v) => ({
                tag: "el-input-number",
                prop: "batchSize",
                min:0,
                label: "Produces batch size in bytes",
                value: v,
            }),
            bufferMemory: (v) => ({
                tag: "el-input-number",
                prop: "bufferMemory",
                min:0,
                label: "Client buffer max size in bytes",
                value: v,
            }),
            linger:(v) => ({
                tag: "el-input-number",
                prop: "linger",
                label:"Time to buffer locally (ms)",
                min:0,
                value:v,
            }),
            acks: (v) => ({
                tag: "el-select",
                prop: "acks",
                label: "Number of acknowledgments",
                value: v,
                cls: [
                    {value: "all"},
                    {value: "-1"},
                    {value: "0"},
                    {value: "1"},
                ],
            }),
            keySerializer: (v) => ({
                tag: "el-input",
                prop: "keySerializer",
                label: "Key serializer",
                value: v,
            }),
            valueSerializer: (v) => ({
                tag: "el-input",
                prop: "valueSerializer",
                label: "Value serializer",
                value: v,
            }),
            otherProperties: (v) => ({
                tag: "item-table",
                prop: "otherProperties",
                value: v,
                label: "Other properties",
                showPagination: false,
                showSaveBtn:false,
                format: (v) => {
                    const isArray = Array.isArray(v);
                    if (isArray) {
                        // array 转 obj
                        const o = {};
                        v.forEach((l) => {
                            const {key, value} = l;
                            o[key] = value;
                        });
                        return o;
                    } else {
                        const l = [];
                        Object.keys(v).forEach((k) => {
                            const d = {key: k, value: v[k]};
                            l.push(d);
                        });
                        return l;
                    }
                },
                column: [
                    {prop: "key", label: "key", form: {}},
                    {prop: "value", label: "value", form: {}},
                ],
            }),
            addMetadataKeyValuesAsKafkaHeaders: (v, d) => ({
                tag: "div",
                class: "els-form--item",
                hooks: {
                    created() {
                        this.context.charset = {div: this};
                    },
                },
                cls: [
                    {
                        tag: "el-checkbox",
                        prop: "addMetadataKeyValuesAsKafkaHeaders",
                        value: v,
                        cls: "Add Message metadata key-value pairs to Kafka record headers",
                        hooks: {
                            updated() {
                                const b = this.$("value");
                                this.context.charset.target &&
                                this.context.charset.target.resetChild(b);
                            },
                        },
                    },
                    {
                        tag: "p",
                        class: "editor--tip",
                        cls:
                            "If selected, key-value pairs from message metadata will be added to the outgoing records headers as byte arrays with predefined charset encoding.",
                    },
                    {
                        tag: "div",
                        hooks: {
                            created() {
                                this.context.charset.target = this;
                                this.resetChild = (b) => {
                                    const cls = {
                                        tag: "el-select",
                                        prop: "kafkaHeadersCharset",
                                        value: d.kafkaHeadersCharset,
                                        label: "Charset encoding",
                                        cls: dicts.CODES,
                                    };
                                    if (b) {
                                        this.$("cls", cls);
                                    } else {
                                        this.$("cls", "");
                                    }
                                };
                            },
                            mounted() {
                                const b = d.addMetadataKeyValuesAsKafkaHeaders;
                                this.resetChild(b);
                            },
                        },
                    },
                ],
            }),
            kafkaHeadersCharset: () => "",
        },
        "send email":{
          useSystemSmtpSettings:(v) => ({tag:'el-checkbox',prop:'useSystemSmtpSettings',cls:'Use system SMTP settings',value:v,hooks:{
            created(){this.context.source.SendEmail = [];this.attrsToggle = (bool,d) => {
              const elems = this.context.source.SendEmail;
              elems.forEach(e => {e.show(bool,d)})
            }},
            updated(){this.attrsToggle(!this.$('value'),this.context.source.formData.configuration)}
          }}),
          "smtpProtocol":(v,d) => ({tag:'el-select',prop:'smtpProtocol',label:'Protocol',value:v,cls:dicts.SMTP_PROTOCOL,hooks:{
            created(){this.context.source.SendEmail.push(this);this.show = (bool) => {this.$('vif',bool)}},
            mounted(){this.show(!this.context.source.formData.configuration.useSystemSmtpSettings)}
          }}),
          "smtpHost":(v,d) => ({tag:'el-input',prop:'smtpHost',label:'SMTP host',required:true,value:v,hooks:{
            created(){this.context.source.SendEmail.push(this);this.show = (bool) => {this.$('vif',bool)}},
            mounted(){this.show(!this.context.source.formData.configuration.useSystemSmtpSettings)}
          }}),
          "smtpPort":(v,d) => ({tag:'el-input-number',prop:'smtpPort',label:'SMTP port',required:true,value:v,min:1,max:65535,hooks:{
            created(){this.context.source.SendEmail.push(this);this.show = (bool) => {this.$('vif',bool)}},
            mounted(){this.show(!this.context.source.formData.configuration.useSystemSmtpSettings)}
          }}),
          "timeout":(v,d) => ({tag:'el-input-number',prop:'timeout',label:'Timeout ms',required:true,value:v,hooks:{
            created(){this.context.source.SendEmail.push(this);this.show = (bool) => {this.$('vif',bool)}},
            mounted(){this.show(!this.context.source.formData.configuration.useSystemSmtpSettings)}
          }}),
          "enableTls":(v,d) => ({tag:'el-checkbox',prop:'enableTls',cls:'Enable TLS',value:v,hooks:{
            created(){this.context.source.SendEmail.push(this);this.show = (bool) => {this.$('vif',bool)}},
            mounted(){this.show(!this.context.source.formData.configuration.useSystemSmtpSettings)},
            updated(){this.$('vif') && this.context.source.tlsVersion.show(true,{enableTls:this.$('value')})},
          }}),
          "tlsVersion":(v,d) => ({tag:'el-select',prop:'tlsVersion',cls:dicts.TLS_VERSION,label:'TLS version',value:v,hooks:{
            created(){this.context.source.tlsVersion = this;this.context.source.SendEmail.push(this);this.show = (bool,d) => {this.$('vif',bool && d.enableTls)}},
            mounted(){this.show(!this.context.source.formData.configuration.useSystemSmtpSettings,this.context.source.formData.configuration)}
          }}),
          "enableProxy":(v,d) => ({tag:'el-checkbox',prop:'enableProxy',cls:'Enable proxy',value:v,hooks:{
            created(){this.context.source.SendEmail.push(this);this.show = (bool) => {this.$('vif',bool)};this.context.source.enableProxy = []},
            mounted(){this.show(!this.context.source.formData.configuration.useSystemSmtpSettings)},
            updated(){this.$('vif') && this.context.source.enableProxy.forEach(e => {e.show(true,{enableProxy:this.$('value')})})},
          }}),
          "proxyHost":(v,d) => ({tag:'el-input',prop:'proxyHost',label:'Proxy host',value:v,hooks:{
            created(){this.context.source.enableProxy.push(this);this.context.source.SendEmail.push(this);this.show = (bool,d) => {this.$('vif',bool && d.enableProxy)}},
            mounted(){this.show(!this.context.source.formData.configuration.useSystemSmtpSettings,this.context.source.formData.configuration)}
          }}),
          "proxyPort":(v,d) => ({tag:'el-input-number',prop:'proxyPort',label:'Proxy port',value:v,hooks:{
            created(){this.context.source.enableProxy.push(this);this.context.source.SendEmail.push(this);this.show = (bool,d) => {this.$('vif',bool && d.enableProxy)}},
            mounted(){this.show(!this.context.source.formData.configuration.useSystemSmtpSettings,this.context.source.formData.configuration)}
          }}),
          "proxyUser":(v,d) => ({tag:'el-input',prop:'proxyUser',label:'Proxy user',value:v,hooks:{
            created(){this.context.source.enableProxy.push(this);this.context.source.SendEmail.push(this);this.show = (bool,d) => {this.$('vif',bool && d.enableProxy)}},
            mounted(){this.show(!this.context.source.formData.configuration.useSystemSmtpSettings,this.context.source.formData.configuration)}
          }}),
          "proxyPassword":(v,d) => ({tag:'el-input',prop:'proxyPassword',label:'Proxy password',value:v,hooks:{
            created(){this.context.source.enableProxy.push(this);this.context.source.SendEmail.push(this);this.show = (bool,d) => {this.$('vif',bool && d.enableProxy)}},
            mounted(){this.show(!this.context.source.formData.configuration.useSystemSmtpSettings,this.context.source.formData.configuration)}
          }}),
          "username":(v,d) => ({tag:'el-input',prop:'username',label:'Username',value:v,hooks:{
            created(){this.context.source.SendEmail.push(this);this.show = (bool) => {this.$('vif',bool)}},
            mounted(){this.show(!this.context.source.formData.configuration.useSystemSmtpSettings)}
          }}),
          "password":(v,d) => ({tag:'el-input',prop:'password',label:'Password',value:v,hooks:{
            created(){this.context.source.SendEmail.push(this);this.show = (bool) => {this.$('vif',bool)}},
            mounted(){this.show(!this.context.source.formData.configuration.useSystemSmtpSettings)}
          }}),
        },
        "aws sqs": {
            queueType:(v) => ({
                tag: "el-select",
                prop: "queueType",
                label: "Queue type",
                value: v,
                cls: [{label:"Standard",value: "STANDARD"},
                    {label:"FIFO",value: "FIFO"}],
            }),
            delaySeconds:(v) => ({
                tag: "el-input-number",
                prop: "delaySeconds",
                label: "Delay (seconds)",
                value: v,
                min:0,
            }),
            messageAttributes:(v) => ({
                tag: "item-table",
                prop: "messageAttributes",
                value: v,
                label: "Message attributes",
                showPagination: false,
                showSaveBtn:false,
                format: (v) => {
                    const isArray = Array.isArray(v);
                    if (isArray) {
                        // array 转 obj
                        const o = {};
                        v.forEach((l) => {
                            const {key, value} = l;
                            o[key] = value;
                        });
                        return o;
                    } else {
                        const l = [];
                        Object.keys(v).forEach((k) => {
                            const d = {key: k, value: v[k]};
                            l.push(d);
                        });
                        return l;
                    }
                },
                column: [
                    {prop: "key", label: "Name", form: {}},
                    {prop: "value", label: "value", form: {}},
                ],
            }),
        },
        "数梦数据推送":{
            port:(v) => ({
                tag: "el-input",
                prop: "port",
                label: "Port",
                value: v,
                rules: [{pattern: /^\d+$/, message: lan['ruleDesignPage']['inputNum']}],
            }),
            connectTimeoutSec:(v) => ({
                tag: "el-input-number",
                prop: "connectTimeoutSec",
                label: "Connection timeout (sec)",
                value: v,
                min:1,
            }),
        },
        "rest api call":{
            restEndpointUrlPattern:(v) => ({tag:'el-form-item',label:'Endpoint URL pattern',cls:[
                {tag:'el-input',prop:'restEndpointUrlPattern',value:v,elFormItem:false},
                {tag:'i',class:'tip',cls:'HTTP URL address pattern, use ${metaKeyName} to substitute variables from metadata'}
            ]}),
            //
            requestMethod:(v) => ({tag:'el-select',prop:'requestMethod',label:'Request method',value:v,cls:dicts.REQUEST_METHODS}),
            enableProxy:(v) => ({tag:'el-checkbox',prop:'enableProxy',cls:' Enable proxy ',value:v,hooks:{
                updated(){
                    const v = this.$('value')
                    this.context.source.useSystemProxyProperties.$('vif',v)
                    this.context.source.useSimpleClientHttpFactory.$('vif',!v)
                }
            }}),
            useSystemProxyProperties:(v) => ({tag:'el-checkbox',prop:'useSystemProxyProperties',cls:' Use system proxy properties ',value:v,hooks:{
                created(){this.context.source.useSystemProxyProperties = this},
                mounted(){const d = this.context.source.formData.configuration || {};this.$('vif',d.enableProxy)},
                updated(){
                    const v = this.$('value');
                    const t = this.$('vif');
                    const b = t && !v;
                    this.context.source.proxyScheme.$('vif',b)
                    this.context.source.proxyHost.$('vif',b)
                    this.context.source.proxyPort.$('vif',b)
                    this.context.source.proxyUser.$('vif',b)
                    this.context.source.proxyPassword.$('vif',b)
                }
            }}),
            proxyScheme:(v) => ({tag:'el-select',elFormItem:{class:'inline'},prop:'proxyScheme',label:'Proxy scheme',value:v,cls:[{label:'http',value:'http'},{label:'https',value:'https'}],hooks:{
                created(){this.context.source.proxyScheme = this;},
                mounted(){const d = this.context.source.formData.configuration || {};this.$('vif',d.enableProxy && !d.useSystemProxyProperties)}
            }}),
            proxyHost:(v) => ({tag:'el-input',elFormItem:{class:'inline'},prop:'proxyHost',label:'Proxy host',value:v,hooks:{
                created(){this.context.source.proxyHost = this;},
                mounted(){const d = this.context.source.formData.configuration || {};this.$('vif',d.enableProxy && !d.useSystemProxyProperties)}
            }}),
            proxyPort:(v) => ({tag:'el-input',elFormItem:{class:'inline'},prop:'proxyPort',label:'Proxy port',value:v,hooks:{
                created(){this.context.source.proxyPort = this;},
                mounted(){const d = this.context.source.formData.configuration || {};this.$('vif',d.enableProxy && !d.useSystemProxyProperties)}
            }}),
            proxyUser:(v) => ({tag:'el-input',prop:'proxyUser',label:'Proxy user',value:v,hooks:{
                created(){this.context.source.proxyUser = this;},
                mounted(){const d = this.context.source.formData.configuration || {};this.$('vif',d.enableProxy && !d.useSystemProxyProperties)}
            }}),
            proxyPassword:(v,d) => ({tag:'el-input',prop:'proxyPassword',label:'Proxy password',value:v,hooks:{
                created(){this.context.source.proxyPassword = this;},
                mounted(){this.$('vif',d.enableProxy && !d.useSystemProxyProperties)}
            }}),
            useSimpleClientHttpFactory:(v) => ({tag:'el-checkbox',prop:'useSimpleClientHttpFactory',value:v,cls:' Use simple client HTTP factory ',hooks:{
                created(){this.context.source.useSimpleClientHttpFactory = this;},
                mounted(){const d = this.context.source.formData.configuration || {};this.$('vif',!d.enableProxy)},
                updated(){
                    const v = this.$('value');
                    const t = this.$('vif')
                    this.context.source.readTimeoutMs.$('vif',!t || !v)
                },
            }}),
            readTimeoutMs:(v) => ({tag:'el-form-item',label:'Read timeout in millis',hooks:{
                created(){this.context.source.readTimeoutMs = this;},
                mounted(){const d = this.context.source.formData.configuration || {};this.$('vif',d.enableProxy || !d.useSimpleClientHttpFactory)}
            },cls:[
                {tag:'el-input-number',prop:'readTimeoutMs',value:v,min:0,elFormItem:false},
                {tag:'i',class:'tip',cls:'The value of 0 means an infinite timeout'}
            ]}),
            //
            maxParallelRequestsCount:(v) => ({tag:'el-form-item',label:'Max number of parallel requests',cls:[
                {tag:'el-input-number',prop:'maxParallelRequestsCount',value:v,min:0,elFormItem:false},
                {tag:'i',class:'tip',cls:'The value of 0 specifies no limit in parallel processing'}
            ]}),
            headers:(v) => ({tag:'el-form-item',label:'Headers',cls:[
                {tag:'i',class:'tip',cls:'Use ${metaKeyName} in header/value fields to substitute variables from metadata'},
                {tag:'item-table',prop:'headers',elFormItem:false,showPagination:false,showSaveBtn:false,showMenu:true,column:[{prop:'key',label:'Header',form:{}},{prop:'value',label:'Value',form:{}}],value:v,format:transObjOrArray}
            ]}),
            //
            useRedisQueueForMsgPersistence:(v) => ({tag:'el-checkbox',prop:'useRedisQueueForMsgPersistence',value:v,cls:' Use redis queue for message persistence ',hooks:{updated(){
                const v = this.$('value')
                this.context.source.trimQueue && this.context.source.trimQueue.$('vif',v)
                this.context.source.maxQueueSize && this.context.source.maxQueueSize.$('vif',v)
            }}}),
            trimQueue:(v) => ({tag:'el-checkbox',prop:'trimQueue',value:v,cls:' Trim redis queue ',hooks:{created(){this.context.source.trimQueue = this;},mounted(){const d = this.context.source.formData.configuration || {};this.$('vif',d.useRedisQueueForMsgPersistence)}}}),
            maxQueueSize:(v) => ({tag:'el-input-number',prop:'maxQueueSize',label:'Redis queue max size',value:v,min:0,hooks:{created(){this.context.source.maxQueueSize = this;},mounted(){const d = this.context.source.formData.configuration || {};this.$('vif',d.useRedisQueueForMsgPersistence)}}}),
            credentials:(v = {}) => ({tag:'els-form-group',title:'Credentials',hooks:{created(){this.context.credentials = {};this.reset = (type) => {
                if(type == 'Anonymous'){
                    this.context.credentials.basic && this.context.credentials.basic.$('vif',false)
                    this.context.credentials.pem && this.context.credentials.pem.$('vif',false)
                }
                else if(type == 'Basic'){
                    this.context.credentials.basic && this.context.credentials.basic.$('vif',true)
                    this.context.credentials.pem && this.context.credentials.pem.$('vif',false)
                }
                else if(type == 'PEM'){
                    this.context.credentials.basic && this.context.credentials.basic.$('vif',false)
                    this.context.credentials.pem && this.context.credentials.pem.$('vif',true)
                }
            }}},cls:[
                {tag:'el-select',prop:'credentials.type',label:'Credentials',value:v.type,cls:[{value:'Anonymous'},{value:'Basic'},{value:'PEM'}],hooks:{mounted(){this.context.parent.reset(this.$('value'))},updated(){this.context.parent.reset(this.$('value'))}}},
                {tag:'els-form-group',title:'Basic',hooks:{created(){this.context.credentials.basic = this;this.$('vif',false)}},cls:[
                    {prop:'credentials.username',label:'Username',value:v.username},
                    {prop:'credentials.password',label:'Password',value:v.password}
                ]},
                {tag:'els-form-group',title:'PEM',hooks:{created(){this.context.credentials.pem = this;this.$('vif',false)}},cls:[
                    {tag:'span',class:'editor--tip',cls:'At least Server CA certificate file or a pair of Client certificate and Client private key files are required'},
                    {tag:'els-file-data',prop:'credentials',label:'Server CA certificate file *',value:v,keys:{name:'caCertFileName',data:'caCert'}},
                    {tag:'els-file-data',prop:'credentials',label:'Client certificate file *',value:v,keys:{name:'certFileName',data:'cert'}},
                    {tag:'els-file-data',prop:'credentials',label:'Client private key file *',value:v,keys:{name:'privateKeyFileName',data:'privateKey'}},
                    {prop:'credentials.password',label:'Private key password',type:'password',value:v.password,showPassword:true}

                ]}
            ]})
            // credentials:(v = {}) => ({tag:'el-select',prop:'credentials',label:'Credentials',value:v.type})

        },
        "工业机理模型":{
            mechanismType:(v) => ({tag:'el-cascader',prop:'mechanismType',label:lan['ruleDesignPage']['selIndModel'],style:"width:100%",value:v,props:{options:mechanismTypeOptions}}),
            mechanism:(v) => ""
        },
        "influxdb":{
            username:(v) => ({prop:'username',label:'username',value:v,required:true}),
            password:(v) => ({prop:'password',label:'password',value:v,required:true}),
            database:(v) => ({prop:'database',label:'database',value:v,required:true}),
            measurement:(v) => ({prop:'measurement',label:'measurement',value:v,required:true}),
            retentionPolicy:(v) => ({prop:'retentionPolicy',label:'retentionPolicy',value:v,required:true}),
            tags:(v) => ({tag:'item-table',prop:'tags',label:'tags',value:v,initLine:0,showMenu:true,showSaveBtn:false,showPagination:false,column:[{prop:'key',label:lan['ruleDesignPage']['keyName'],form:{}},{prop:'value',label:lan['d']['type'],form:{tag:'el-select',value:'String',cls:[{value:'String'}]}}],format:transObjOrArray}),
            feilds:(v) => ({tag:'item-table',prop:'feilds',label:'feilds',value:v,showMenu:true,showSaveBtn:false,showPagination:false,column:[{prop:'key',label:lan['ruleDesignPage']['keyName'],form:{}},{prop:'value',label:lan['d']['type'],form:{tag:'el-select',value:'String',cls:[{value:'String'},{value:'Float'}]}}],format:transObjOrArray})
        },
        "Redis":{
            redisType:(v) => ({tag:"el-select",prop:'redisType',value:v,label:$t.Redis.redisType,cls:[
                {label:$t.Redis.standalone,value:"standalone"},
                {label:$t.Redis.sharded,value:"sharded"},
                {label:$t.Redis.cluster,value:"cluster"},
            ]}),
            maxTotal:(v) => ({tag:"el-input-number",prop:"maxTotal",value:v,min:0,label:$t.Redis.maxTotal}),
            maxIdle:(v) => ({tag:"el-input-number",prop:"maxIdle",value:v,min:-1,label:$t.Redis.maxIdle}),
            maxWaitMillis:(v) => ({tag:"el-input-number",prop:"maxWaitMillis",value:v,min:-1,label:$t.Redis.maxWaitMillis}),
            testOnBorrow:(v) => ({tag:"el-switch",prop:"testOnBorrow",value:v,label:$t.Redis.testOnBorrow}),
            testWhileIdle:(v) => ({tag:"el-switch",prop:"testWhileIdle",value:v,label:$t.Redis.testWhileIdle}),
            password:(v) => ({tag:"el-input",prop:"password",value:v,label:$t.Redis.password}),
            hostAndPortsNodes:(v) => ({tag:"item-table",prop:"hostAndPortsNodes",value:v,label:$t.Redis.hostAndPortsNodes,showMenu:true,showSaveBtn:false,showPagination:false,format:(v) => transArray(v,'HostAndPort'),column:[{prop:"HostAndPort",label:$t.Redis.HostAndPort,form:{}}]}),
            timeout:(v = 2000) => ({tag:"el-input-number",prop:"timeout",value:v,min:0,label:$t.Redis.timeout}),
            expireSecond:(v) => ({tag:"el-input-number",prop:"expireSecond",value:v,min:0,label:$t.Redis.expireSecond}),
            dataStoreType:(v) => ({tag:"el-select",prop:'dataStoreType',value:v,label:$t.Redis.dataStoreType,cls:'string,hash,list,set,zset'.split(',').map(n => ({value:n}))}),
        }
    },
    TRANSFORMATION: {
        "change originator": {
            originatorSource: (v) => ({
                tag: "el-select",
                prop: "originatorSource",
                label: "Originator source",
                value: v,
                cls: dicts.ORIGINATOR_SOURCE,
                hooks: {
                    updated(v) {
                        this.context.source.relationsQuery.$("vif", this.$("value") === "RELATED");
                    }
                },
            }),
            relationsQuery: (v, d) => ({
                tag: "div",
                class: "els-form--item",
                hooks: {
                    created() {
                        this.context.source.relationsQuery = this;
                        this.$(
                            "vif",
                            this.context.source.formData.configuration.originatorSource === "RELATED"
                        );
                    }
                },
                cls: [
                    {
                        tag: "el-checkbox",
                        prop: "relationsQuery.fetchLastLevelOnly",
                        label: "Relations query",
                        value: v.fetchLastLevelOnly,
                        cls: "Fetch last level relation only"
                    }, {
                        tag: "el-select",
                        prop: "relationsQuery.direction",
                        label: lan['ruleDesignPage']['direction'],
                        value: v.direction,
                        cls: dicts.DIRECTION,
                    }, {
                        tag: "el-input-number",
                        prop: "relationsQuery.maxLevel",
                        label: "Max relation level",
                        value: v.maxLevel,
                        min: 1
                    }, {
                        tag: "item-table",
                        prop: "relationsQuery.filters",
                        value: v.filters,
                        label: lan['ruleDesignPage']['assFilter'],
                        showPagination: false,
                        showSaveBtn:false,
                        column: [
                            // {prop: "relationType", label: lan['d']['type'], form: {tag: "el-autocomplete", cls: dicts.RELATION_TYPE}},
                            {prop: "relationType", label: lan['d']['type'], form: {tag: "el-autocomplete", fetchSuggestions:(q,cb) => {
                                const d = dicts.RELATION_TYPE
                                const res = q ? d.filter(e => e.value.toLowerCase().indexOf(q.toLowerCase()) >= 0) : d;
                                cb(res)
                            }}},
                            {
                                prop: "entityTypes",
                                label:  lan['ruleDesignPage']['entityType'],
                                form: {tag: "el-select", cls: dicts.GENERATOR_TYPES, multiple: true}
                            },
                        ],
                    }
                ]
            })
        },
        script: {
            jsScript: (v, d, e) => ({
                tag: "div",
                class: "els-form--item",
                cls: [
                    {
                        tag: "p",
                        class: "editor--tip",
                        cls: [{tag:'b',class:'form-item--required',cls:'*',},"function Transform(msg, metadata, msgType) {"],
                    },
                    {
                        tag: "els-monaco-editor",
                        prop: "jsScript",
                        value: v,
                        showHeader: false,
                        theme: "vs-light",
                        hooks: {
                            created() {
                                this.context.source.jsScript = this;
                            }
                        }
                    },
                    {tag: "p", class: "editor--tip", cls: "}"},
                    {
                        tag: 'el-button',
                        cls: 'function test',
                        type: 'primary',
                        size: 'small',
                        icon: 'el-icon-s-tools',
                        hooks: {
                            context(ctx) {
                                ctx.on = {
                                    click: () => {
                                        e.showFunTest('TRANSFORMATION.script.jsScript', this.context.source.jsScript.$('value'), this.context.source.jsScript);
                                    }
                                }
                            }
                        }
                    }
                ],
            }),
        },
        'to email':{
          fromTemplate:(v) => ({tag:'div',class: "els-form--item",cls:[
            {tag:'el-input',prop:'fromTemplate',value:v,label:'From Template'},
            {tag:'i',class:'editor--tip',cls:'From address template, use ${metaKeyName} to substitute variables from metadata'}
          ]}),
          toTemplate:(v) => ({tag:'div',class: "els-form--item",cls:[
            {tag:'el-input',prop:'toTemplate',value:v,label:'To Template'},
            {tag:'i',class:'editor--tip',cls:'Comma separated address list, use ${metaKeyName} to substitute variables from metadata'}
          ]}),
          ccTemplate:(v) => ({tag:'div',class: "els-form--item",cls:[
            {tag:'el-input',prop:'ccTemplate',value:v,label:'Cc Template'},
            {tag:'i',class:'editor--tip',cls:'Comma separated address list, use ${metaKeyName} to substitute variables from metadata'}
          ]}),
          bccTemplate:(v) => ({tag:'div',class: "els-form--item",cls:[
            {tag:'el-input',prop:'bccTemplate',value:v,label:'Bcc Template'},
            {tag:'i',class:'editor--tip',cls:'Comma separated address list, use ${metaKeyName} to substitute variables from metadata'}
          ]}),
          subjectTemplate:(v) => ({tag:'div',class: "els-form--item",cls:[
            {tag:'el-input',prop:'subjectTemplate',value:v,label:'Subject Template'},
            {tag:'i',class:'editor--tip',cls:'Mail subject template, use ${metaKeyName} to substitute variables from metadata'}
          ]}),
          bodyTemplate:(v) => ({tag:'div',class: "els-form--item",cls:[
            {tag:'el-input',prop:'bodyTemplate',value:v,label:'Body Template',type:'textarea'},
            {tag:'i',class:'editor--tip',cls:'Mail body template, use ${metaKeyName} to substitute variables from metadata'}
          ]}),
        }
    },
};
// 表单元素排序
const formElemSortConfig = {
    FILTER: {
        "check relation": ["checkForSingleEntity", "direction", "entityType", "entityId", "relationType"]
    },
    ENRICHMENT: {
        "originator attributes": ["tellFailureIfAbsent", "clientAttributeNames", "sharedAttributeNames", "serverAttributeNames", "latestTsKeyNames", "getLatestValueWithTs"],
        "originator telemetry": ["latestTsKeyNames", "fetchMode", "useMetadataIntervalPatterns", "startIntervalPattern", "endIntervalPattern", "startInterval", "startIntervalTimeUnit", "endInterval", "endIntervalTimeUnit", "orderBy", "limit"],
        "related attributes":['relationsQuery','telemetry','attrMapping'],
    },
    ACTION: {
        "delay": ["useMetadataPeriodInSecondsPatterns", "periodInSeconds", "periodInSecondsPattern", "maxPendingMsgs"],
        "create alarm": ["alarmDetailsBuildJs", "useMessageAlarmData", "alarmType", "severity", "propagate", "relationTypes"],
        "clear alarm": ["alarmDetailsBuildJs", "alarmType"],
        "assign to customer": ["customerNamePattern", "createCustomerIfNotExists", "customerCacheExpiration"],
        "delete relation": ["deleteForSingleEntity", "direction", "entityType", "entityNamePattern", "entityTypePattern", "entityCacheExpiration"],
        generator:["msgCount","periodInSeconds","originatorType","originatorId","jsScript"],
    },
    EXTERNAL: {
      "send email":['useSystemSmtpSettings','smtpProtocol','smtpHost','smtpPort','timeout','enableTls','tlsVersion','enableProxy','proxyHost','proxyPort','proxyUser','proxyPassword','username','password'],
      "rest api call":["restEndpointUrlPattern","requestMethod","enableProxy","useSystemProxyProperties","proxyScheme","proxyHost","proxyPort","proxyUser","proxyPassword","useSimpleClientHttpFactory","readTimeoutMs","maxParallelRequestsCount","headers","useRedisQueueForMsgPersistence","trimQueue","maxQueueSize","credentials"],
      "kafka":["topicPattern","bootstrapServers","retries","batchSize","linger","bufferMemory","acks","keySerializer","valueSerializer","otherProperties","addMetadataKeyValuesAsKafkaHeaders"],
    },
    TRANSFORMATION: {},
}
// 节点方法测试数据
export const elemFunTestConfig = {
    FILTER: {
        script: {
            jsScript: {
                // argNames: ["msg", "metadata", "msgType"],
                // msg:{temperature:22.4,humidity:78},
                // metadata:{deviceName: "Test Device",deviceType: "default",ts: "1627438621269"},
                // msgType:'POST_TELEMETRY_REQUEST',
                functionName: 'Filter',
                scriptType: 'filter'
            }
        },
        switch: {
            jsScript: {
                // argNames: ["msg", "metadata", "msgType"],
                // msg:{temperature:22.4,humidity:78},
                // metadata:{deviceName: "Test Device",deviceType: "default",ts: "1627438621269"},
                // msgType:'POST_TELEMETRY_REQUEST',
                functionName: 'Switch',
                scriptType: 'switch'
            }
        }
    },
    TRANSFORMATION: {
        script: {
            jsScript: {
                // argNames: ["msg", "metadata", "msgType"],
                // msg:{temperature:22.4,humidity:78},
                // metadata:{deviceName: "Test Device",deviceType: "default",ts: "1627438621269"},
                // msgType:'POST_TELEMETRY_REQUEST',
                functionName: 'Transform',
                scriptType: 'update'
            }
        },
    },
    ACTION: {
        "create alarm": {
            alarmDetailsBuildJs: {
                functionName: 'Details',
                scriptType: 'json'
            }
        },
        log: {
            jsScript: {
                functionName: 'ToString',
                scriptType: 'string'
            }
        },
        "clear alarm": {
            alarmDetailsBuildJs: {
                functionName: 'Details',
                scriptType: 'json'
            }
        },
        generator:{
          jsScript:{
            functionName: 'Generate',
            scriptType: 'generate'
          }
        }
    }
}
// 成功失败状态字典
const successOrFail = [
    {label: lan['ruleDesignPage']['success'], value: true},
    {label: lan['ruleDesignPage']['fail'], value: false},
];
// 导出

// 字典项
export const dicts = {
    EVENT_TYPES: [
        {label: lan['ruleDesignPage']['debug'], value: "DEBUG_RULE_NODE"},
        {label: lan['ruleDesignPage']['typeSta'], value: "STATS"},
        {label: lan['ruleDesignPage']['lifeEvents'], value: "LC_EVENT"},
        {label: lan['ruleDesignPage']['error'], value: "ERROR"},
    ],
    CODES: [
        {value: "US-ASCII"},
        {value: "ISO-8859-1"},
        {value: "UTF-8"},
        {value: "UTF-16BE"},
        {value: "UTF-16LE"},
        {value: "UTF-16"},
    ],
    GENERATOR_TYPES: [
        {label: lan['ruleDesignPage']['device'], value: "DEVICE"},
        {label: lan['ruleDesignPage']['assets'], value: "ASSET"},
        {label: lan['ruleDesignPage']['entView'], value: "ENTITY_VIEW"},
        {label: lan['ruleDesignPage']['tenant'], value: "TENANT"},
        {label: lan['ruleDesignPage']['customer'], value: "CUSTOMER"},
        {label: lan['ruleDesignPage']['dashboard'], value: "DASHBOARD"},
    ],
    ALARM_STATUS: [
        {label: lan['ruleDesignPage']['acAns'], value: "ACTIVE_ACK"},
        {label: lan['ruleDesignPage']['acNotAns'], value: "ACTIVE_UNACK"},
        {label: lan['ruleDesignPage']['clearUnac'], value: "CLEARED_UNACK"},
        {label: lan['ruleDesignPage']['clearAck'], value: "CLEARED_ACK"},
    ],
    PERIMETER_TYPE: [
        {label: "Circle", value: "CIRCLE"},
        {label: "Polygon", value: "POLYGON"},
    ],
    ATTRIBUTES_TYPES: [
        {label: lan['ruleDesignPage']['clientPro'], value: "CLIENT_SCOPE"},
        {label: lan['ruleDesignPage']['serverPro'], value: "SERVER_SCOPE"},
        {label: lan['ruleDesignPage']['sharePro'], value: "SHARED_SCOPE"},
    ],
    ORIGINATOR_TYPES: [
        {label: lan['ruleDesignPage']['device'], value: "DEVICE"},
        {label: lan['ruleDesignPage']['assets'], value: "ASSET"},
        {label: lan['ruleDesignPage']['entView'], value: "ENTITY_VIEW"},
        {label: lan['ruleDesignPage']['tenant'], value: "TENANT"},
        {label: lan['ruleDesignPage']['customer'], value: "CUSTOMER"},
        {label: lan['ruleDesignPage']['user'], value: "USER"},
        {label: lan['ruleDesignPage']['dashboard'], value: "DASHBOARD"},
        {label: lan['ruleDesignPage']['ruleChain'], value: "RULE_CHAIN"},
        {label: lan['ruleDesignPage']['ruleNode'], value: "RULE_CHAIN"},
    ],
    DIRECTION: [
        {label: lan['ruleDesignPage']['from'], value: "FROM"},
        {label: lan['ruleDesignPage']['to'], value: "TO"},
    ],
    RELATION_TYPE: [{value: 'Contains'}, {value: 'Manages'}],
    MESSAGE_TYPES: [
        {label: "Post attributes", value: "POST_ATTRIBUTES_REQUEST"},
        {label: "Post telemetry", value: "POST_TELEMETRY_REQUEST"},
        {label: "RPC Request from Device", value: "TO_SERVER_RPC_REQUEST"},
        {label: "RPC Request to Device", value: "RPC_CALL_FROM_SERVER_TO_DEVICE"},
        {label: "Activity Event", value: "ACTIVITY_EVENT"},
        {label: "Inactivity Event", value: "INACTIVITY_EVENT"},
        {label: "Connect Event", value: "CONNECT_EVENT"},
        {label: "Disconnect Event", value: "DISCONNECT_EVENT"},
        {label: "Entity Created", value: "ENTITY_CREATED"},
        {label: "Entity Updated", value: "ENTITY_UPDATED"},
        {label: "Entity Deleted", value: "ENTITY_DELETED"},
        {label: "Entity Assigned", value: "ENTITY_ASSIGNED"},
        {label: "Entity Unassigned", value: "ENTITY_UNASSIGNED"},
        {label: "Attributes Updated", value: "ATTRIBUTES_UPDATED"},
        {label: "Attributes Deleted", value: "ATTRIBUTES_DELETED"},
    ],
    FETCH_MODE: [
        {value: 'FIRST'}, {value: 'LAST'}, {value: 'ALL'},
    ],
    TIME_UNIT: [
        {label: "Milliseconds", value: "MILLISECONDS"},
        {label: "Seconds", value: "SECONDS"},
        {label: "Minutes", value: "MINUTES"},
        {label: "Hours", value: "HOURS"},
        {label: "Days", value: "DAYS"},
    ],
    ORIGINATOR_SOURCE: [
        {label: "Customer", value: "CUSTOMER"},
        {label: "Tenant", value: "TENANT"},
        {label: "Related", value: "RELATED"},
        {label: "Alarm Originator", value: "ALARM_ORIGINATOR"}
    ],
    SEVERITY: [
        {label: lan['ruleDesignPage']['danger'], value: "CRITICAL"},
        {label: lan['ruleDesignPage']['important'], value: "MAJOR"},
        {label: lan['ruleDesignPage']['minor'], value: "MINOR"},
        {label: lan['ruleDesignPage']['warning'], value: "WARNING"},
        {label: lan['ruleDesignPage']['indeterminacy'], value: "INDETERMINATE"},
    ],
    RANGE_UNIT: [
        {label: "Meter", value: "METER"},
        {label: "Kilometer", value: "KILOMETER"},
        {label: "Foot", value: "FOOT"},
        {label: "Mile", value: "MILE"},
        {label: "Nautical mile", value: "NAUTICAL_MILE"},
    ],
    SMTP_PROTOCOL:[
      {label: " SMTP ", value: "smtp"},
      {label: " SMTPS ", value: "smtps"},
    ],
    TLS_VERSION:[
      {label:'TLSv1',value:'TLSv1'},
      {label:'TLSv1.1',value:'TLSv1.1'},
      {label:'TLSv1.2',value:'TLSv1.2'},
      {label:'TLSv1.3',value:'TLSv1.3'},
    ],
    REQUEST_METHODS:[
        {label:'POST',value:'POST'},
        {label:'GET',value:'GET'},
        {label:'PUT',value:'PUT'},
        {label:'DELETE',value:'DELETE'},
    ]
};

export const nodeGroup = {
    FILTER: "滤波器",
    ENRICHMENT: "属性集",
    TRANSFORMATION: "变换",
    ACTION: "动作",
    EXTERNAL: "外部的",
};

// 节点列表样式配置
export const nodeListStyle = {
    FILTER: {
        style: {...nodeStyle, "border-color": "#fcaf17", background: "#ffc20e"},
    },
    ENRICHMENT: {
        style: {...nodeStyle, "border-color": "#7fb80e", background: "#b2d235"},
    },
    TRANSFORMATION: {
        style: {...nodeStyle, "border-color": "#009ad6", background: "#33a3dc"},
    },
    ACTION: {
        style: {...nodeStyle, "border-color": "#f58220", background: "#faa755"},
    },
    EXTERNAL: {
        style: {...nodeStyle, "border-color": "#f05b72", background: "#f391a9"},
    },
};

// models 配置
export const nodeModels = {
    FILTER: {
        ...nodeModel,
        style: {...nodeModelStyle, fill: "#ffc20e", stroke: "#fcaf17"},
    },
    ENRICHMENT: {
        ...nodeModel,
        style: {...nodeModelStyle, fill: "#b2d235", stroke: "#7fb80e"},
    },
    TRANSFORMATION: {
        ...nodeModel,
        style: {...nodeModelStyle, fill: "#33a3dc", stroke: "#009ad6"},
    },
    ACTION: {
        ...nodeModel,
        style: {...nodeModelStyle, fill: "#faa755", stroke: "#f58220"},
    },
    EXTERNAL: {
        ...nodeModel,
        style: {...nodeModelStyle, fill: "#f391a9", stroke: "#f05b72"},
    },
};

// json 数据弹出框
const showData = function (d, title, lang = 'json',width = '500px',height='200px') {
    if(!d) return;
    const h = this.$createElement;
      if(lang == 'json'){d = formatJson(d)}
      this.$msgbox({
        title,
        confirmButtonText:lan['d']['close'],
        // message:h('els-elem',{props:{context:{hooks:{mounted(){console.log(124123123)}}},elem:{tag:'div',style:'height:300px;width:500px;',class:'test---aaa',hooks:{created(){console.log('142131');this.$('cls',[{tag:'els-monaco-editor',readOnly:true,lang,value:JSON.stringify(d),showHeader:false}])}}}}})
        // message: h('div', {class:'message-content'}, d)
        message: h('els-monaco-editor', {style:{width,height},props:{value:d,showHeader:false,lang,theme:'vs',disabled:true,options:{editorOptions:{highlightActiveIndentGuide:true,minimap:{enabled:false},lineNumbers:false}}}})
      }
      ).catch(e => console.log(e))
}

//
export const eventTableColumns = {
    DEBUG_RULE_NODE: [
        {prop: "createdTime", label: lan['ruleDesignPage']['eventTime'], valueType: "date", width: '160px'},
        {prop: "server", label:lan['ruleDesignPage']['server'], minWidth: '120px'},
        {prop: "type", label: lan['d']['type']},
        {prop: "entityName", label: lan['ruleDesignPage']['entity']},
        {prop: "msgId", label: lan['ruleDesignPage']['messID'], minWidth: '150px'},
        {prop: "msgType", label: lan['ruleDesignPage']['messType'], minWidth: '150px'},
        {prop: "relationType", label: lan['ruleDesignPage']['reType'], minWidth: '150px'},
        {
            prop: "data",
            label: lan['ruleDesignPage']['data'],
            width: '80px',
            elsElem: true,
            elem: {
                tag: 'el-button', plain: true, size: 'mini', type: 'text', hooks: {
                    mounted(){
                        if(!this.context.row.data){
                            this.$('cls','');
                        }else{
                            this.$('cls',lan['d']['view'])
                        }
                    },
                    context(ctx) {
                        ctx.on = {
                            click: () => {
                                showData.call(this, this.context.row.data, lan['ruleDesignPage']['data'])
                            }
                        }
                    }
                }
            }
        },
        {
            prop: "metadata",
            label: lan['ruleDesignPage']['metadata'],
            width: '100px',
            elsElem: true,
            elem: {
                tag: 'el-button', plain: true, size: 'mini', type: 'text', hooks: {
                    mounted(){
                        if(!this.context.row.metadata){
                            this.$('cls','');
                        }else{
                            this.$('cls',lan['d']['view'])
                        }
                    },
                    context(ctx) {
                        ctx.on = {
                            click: () => {
                                showData.call(this, this.context.row.metadata, lan['ruleDesignPage']['metadata'])
                            }
                        }
                    }
                }
            }
        },
        {prop: "error", label: lan['d']['error'],
        width: '80px',
        elsElem: true,
        elem: {
            tag: 'el-button', plain: true, size: 'mini', type: 'text',  hooks: {
                mounted(){
                    if(!this.context.row.error){
                        this.$('cls','');
                    }else{
                        this.$('cls',lan['d']['view'])
                    }
                },
                context(ctx) {
                    ctx.on = {
                        click: () => {
                            showData.call(this, this.context.row.error, lan['d']['error'],'shell','70vw')
                        }
                    }
                }
            }
        }
      },
    ],
    STATS: [
        {prop: "createdTime", label: lan['ruleDesignPage']['eventTime'], valueType: "date", width: '160px'},
        {prop: "server", label: lan['ruleDesignPage']['server']},
        {prop: "messagesProcessed", label: lan['ruleDesignPage']['messPro']},
        {prop: "errorsOccurred", label: lan['ruleDesignPage']['errOcc']},
    ],
    LC_EVENT: [
        {prop: "createdTime", label: lan['ruleDesignPage']['eventTime'], valueType: "date", width: '160px'},
        {prop: "server", label: lan['ruleDesignPage']['server']},
        {prop: "event", label: lan['ruleDesignPage']['event']},
        {prop: "success", label: lan['d']['state'], dict: successOrFail},
        {prop: "errorsOccurred", label: lan['d']['error']},
    ],
    ERROR: [
        {prop: "createdTime", label: lan['ruleDesignPage']['eventTime'], valueType: "date", width: '160px'},
        {prop: "server", label: lan['ruleDesignPage']['server']},
        {prop: "method", label: lan['ruleDesignPage']['method']},
        {prop: "error", label: lan['d']['error']},
    ],
};

export const debugFormColumn = [
    {
        tag: "div",
        class: "",
        style: "display:flex;height:50vh;",
        cls: [
            {
                tag: "div",
                style: "flex:1",
                cls: [
                    {
                        tag: "els-monaco-editor",
                        style: "height:300px",
                        theme: "vs-light",
                        lang: "json",
                        prop: "msg",
                        label: lan['ruleDesignPage']['message'],
                        showHeader: false,
                    },
                ],
            },
            {
                tag: "div",
                style: "flex:1",
                cls: [
                    {
                        tag: "item-table",
                        style: "flex:1",
                        label: lan['ruleDesignPage']['metadata'],
                        prop: "metadata",
                        showSaveBtn:false,
                        column: [
                            {label: lan['ruleDesignPage']['key'], prop: "key", form: {}},
                            {label: lan['ruleDesignPage']['value'], prop: "value", form: {}},
                        ],
                    },
                ],
            },
        ],
    },
    {
        tag: "div",
        class: "",
        style: "display:flex",
        cls: [
            {
                tag: "els-monaco-editor",
                theme: "vs-light",
                showHeader: false,
                label: lan['ruleDesignPage']['funBody'],
                prop: "script",
            },
            {
                tag: "els-monaco-editor",
                theme: "vs-light",
                showHeader: false,
                label: lan['ruleDesignPage']['returnRes'],
                prop: "result",
            },
        ],
    },
];

export const buildNodeForm = (ns,d,e) => {
  const [n,...names] = ns;
  const k = names.find(n => has(formElemConfig,n))
  const ks = Object.keys(d)
  if(k == undefined){
    return formElemSort(ks,n).map(k => buildFormElem(n,k,d[k],d,e))
  }
  else{
    return formElemSort(ks,k).map(t => buildFormElem(k,t,d[t],d,e))
  }
}
export const buildFormElem = (gn, p, v, d, e) => {
    if(p === 'version'){
      return {tag:'el-input',prop:p,type:'hidden',elFormItem:false,style:'position:absolute;',value:v || 0}
    }
    const [g, n] = gn.split(".");
    const elems = formElemConfig[g] || {};
    const node = elems[n];

    if (node && node[p]) {
        return node[p](v, d, e);
    }
    return {prop: p, label: p, value: typeof v == "string" ? v : JSON.stringify(v)};
};

export const formElemSort = (keys, gn) => {
    const [g, n] = gn.split(".");
    const elems = formElemSortConfig[g] || {};
    const r = elems[n];
    const res = r ? Array.isArray(r) ? r : typeof r == 'function' ? r(keys) : keys : keys;
    return res;
}
//
const node0 = {
    id: 'node-0',
    x: 0,
    y: 0,
    name: 'input',
    label: 'input',
    type: "mike-modelRect",
    links: {right: {input: false, output: true}}
}
//
export const transformMetadata = (metadata = {}, list = []) => {
    const names = {};
    list &&
    list.forEach(({clazz, name}) => {
        names[clazz] = name;
    });
    const {
        connections,
        nodes: ns,
        firstNodeIndex,
        ruleChainConnections,
    } = metadata;
    // console.log(':::',connections,ns,firstNodeIndex,ruleChainConnections,metadata)
    //
    // console.log(':::',connections,ns,firstNodeIndex,ruleChainConnections,metadata)
    //
    if (!Array.isArray(ns) || ns.length == 0) return undefined;
    //
    // const nodes = [{id:'node-0',x:0,y:0,name:'input',label:'input',type: "mike-modelRect",links: { right: {input:false,output:true} }}];
    const nodes = []
    const forms = {};
    //
    ns.forEach((n) => {
        const {additionalInfo, configuration, debugMode, id: key, name, type} = n;
        const id = key.id;
        const node = {
            x: additionalInfo.layoutX,
            y: additionalInfo.layoutY,
            name: type,
            label: names[type],
            description: name,
            id: id,
            key: {id: key},
            type: "mike-modelRect",
        };
        nodes.push(node);
        forms[id] = {
            data: {
                id,
                name: type,
                label: names[type],
                description: name,
                debugMode,
                configuration,
            },
            event: {id, name: type},
            help: {id, name: type},
        };
    });

    //
    const ids = ns.map((n) => n.id.id);
    //
    const fid = firstNodeIndex != null ? ids[firstNodeIndex] : undefined;
    //
    const temp = {};
    if (fid) {
        temp[fid] = {
            id: "edge-0",
            name: "edge",
            type: "mike-cubic",
            source: "node-0",
            target: fid,
        };
    }
    //
    if (connections && Array.isArray(connections)) {
        connections.forEach((c) => {
            const {fromIndex, toIndex, type} = c;
            const k = fromIndex + "-" + toIndex;
            const source = ids[fromIndex];
            const target = ids[toIndex];
            const edge = temp[k]
                ? temp[k]
                : {
                    id: `edge-${new Date().getTime()}-${Math.round(
                        Math.random() * 99999
                    )}`,
                    name: "edge",
                    type: "mike-cubic",
                    source,
                    target,
                    sourceAnchor: 1,
                    targetAnchor: 3,
                };

            if (edge.label) {
                edge.label.push(type);
            } else {
                edge.label = [type];
            }
            temp[k] = edge;
            const {id, name, label} = edge;
            forms[id] = {data: {id, name, label}};
        });
    }

    //
    const edges = Object.keys(temp).map((k) => temp[k]);
    //
    // console.log("....", nodes, edges, forms);
    return [{nodes, edges}, forms];
};

//
export const transformGraphdata = (graphdata) => {
};


export const elsFormConfig = {elForm:{labelWidth:'200px'}}
